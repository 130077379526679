import { SearchBoxCore, SessionToken } from '@mapbox/search-js-core';
import axios from 'axios';
import mapboxgl, { AnyLayer, AnySourceData } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

export const mapboxToken = 'pk.eyJ1IjoidXNhbWFrb3YiLCJhIjoiY2tkZThhNTZyMjBqMTM0azY0bmEzc3VweSJ9.-Esyr8LgnJYyeiggTsauRA'
export const search = new SearchBoxCore({ accessToken: mapboxToken });
export const sessionToken = new SessionToken();

export const routeLayerSource = {
    'type': 'geojson',
    'data': {
        'type': 'Feature',
        'properties': {},
        'geometry': {
            'type': 'LineString',
            'coordinates': []
        }
    }
}

export const routeOutlineLayerSource = {
    'type': 'geojson',
    'data': {
        'type': 'Feature',
        'properties': {},
        'geometry': {
            'type': 'LineString',
            'coordinates': []
        }
    }
}

export const pointLayerSource: AnySourceData = {
    'type': 'geojson',
    'data': {
        'type': 'FeatureCollection',
        'features': []
    }
}

export const pointLayerConfig: AnyLayer = {
    'id': 'point',
    'type': 'symbol',
    'source': 'points',
    'layout': {
        // get the title name from the source's "title" property
        'text-field': ['get', 'title'],
        'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
        ],
        'text-offset': [0, 0],
        'text-anchor': 'top'
    }
}

export const routeLayerConfig: AnyLayer = {
    'id': 'route',
    'type': 'line',
    'source': 'route',
    'layout': {
        'line-join': 'round',
        'line-cap': 'round'
    },
    'paint': {
        'line-color': '#00b0ff',
        'line-width': 2
    }
}

export const routeOutlineLayerConfig: AnyLayer = {
    'id': 'route-outline',
    'type': 'line',
    'source': 'route',
    'layout': {
        'line-join': 'round',
        'line-cap': 'round'
    },
    'paint': {
        "line-gap-width": 2,
        'line-color': '#1967d2',
        'line-width': 2
    }
}

export const getTolls = async (coordinates: any[]) => {
    const API_URL = process.env.REACT_APP_REF_API_URL;

    if (coordinates.length < 2) return []

    const origin = coordinates[0]

    const destination = coordinates[coordinates.length - 1]

    const waypoints = coordinates.slice(1, coordinates.length - 1)

    const result = await axios.post(`${API_URL}/route`, {
        "vehicle": {
            "is_bus": false,
            "is_special_load": false,
            "is_tractor": false,
            "is_truck": true,
            "has_trailer": true,
            "is_commercial": true,
            "is_RV": false,
            "is_limo": false,
            "is_franchise": false,
            "trailer_number_of_axles": 3,
            "vehicle_type": "tractor_trailer",
            "weight": 0,
            "is_school_bus": false,
            "is_auto": false,
            "trailer_has_dual_tires": true,
            "total_number_of_axles": 5,
            "is_mini_bus": false,
            "is_franchise_bus": false,
            "number_of_axles_without_trailer": 2,
            "length": 300,
            "height": 110,
            "width": 80,
            "is_motorcycle": false,
            "is_motor_home": false,
            "is_tandem_trailer": false,
            "is_tractor_trailer": false,
            "has_dual_tires": true
        },
        "usa_accounts": [
            "Illinois E-ZPass®"
        ],
        "canada_accounts": [],
        "mexico_accounts": [],
        "origin": origin,
        "waypoints": waypoints,
        "destination": destination,
    })

    return result.data
}

mapboxgl.accessToken = mapboxToken;