"use client";

import { CircularProgress } from "@nextui-org/progress";

const Loading = () => {
  return (
    <div className="flex gap-4 max-w-[400px] justify-center items-center w-full mx-auto">
      <CircularProgress isIndeterminate aria-label="loader" size="lg" />
      <h3 className="text-black">Loading...</h3>
    </div>
  );
};

export default Loading;
