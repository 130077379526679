import { TextEditor } from "@/components/text-editor";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  cn,
  Input,
} from "@nextui-org/react";
import { FC, useEffect, useRef, useState } from "react";

import { ReactComponent as EditIcon } from "@/assets/icons/pen-icon.svg";
import { ITemplate } from "./BrandEmail";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";

interface IProps {
  mode: "edit" | "create" | "idle";
  data: ITemplate;
  editMode: boolean;
  onChangeClick: () => void;
  onCancelClick: () => void;
  onSubmit?: (data: ITemplate) => void;
}

const TEMPLATE_AMPLITUDE_EVENTS = {
  edit: {
    subject: AMPLITUDE_EVENTS.onboardingEditTemplateSubjectChanged,
    body: AMPLITUDE_EVENTS.onboardingEditTemplateBodyChanged,
    variable: AMPLITUDE_EVENTS.onboardingEditTemplateVariableClicked,
  },
  create: {
    subject: AMPLITUDE_EVENTS.onboardingCreateTemplateSubjectChanged,
    body: AMPLITUDE_EVENTS.onboardingCreateTemplateBodyChanged,
    variable: AMPLITUDE_EVENTS.onboardingCreateTemplateVariableClicked,
  },
  idle: {
    subject: "",
    body: "",
    variable: "",
  },
};

export const Template: FC<IProps> = ({
  mode,
  data,
  editMode,
  onChangeClick,
  onCancelClick,
  onSubmit,
}) => {
  const quillRef = useRef<any>(null);
  const subjectRef = useRef<any>(null);
  const [activeInput, setActiveInput] = useState<{
    name: string;
    inputRef: React.MutableRefObject<any>;
  } | null>(null);

  const { subject, body } = data;
  const [subjectValue, setSubjectValue] = useState<string>(subject || "");
  const [bodyValue, setBodyValue] = useState<string>(body || "");

  useEffect(() => {
    setSubjectValue(subject);
    setBodyValue(body);
  }, [body, subject]);

  const handleFocus = (input: {
    inputRef: React.MutableRefObject<any>;
    name: string;
  }) => {
    setActiveInput(input);
    sendAmplitudeData(TEMPLATE_AMPLITUDE_EVENTS[mode].subject);
  };

  const insertWord = (word: string) => {
    if (activeInput) {
      const formattedWord = `{{${word}}}`;
      const input = activeInput.inputRef.current;
      if (activeInput.name === "subject") {
        const startPos = input.selectionStart;
        const endPos = input.selectionEnd;
        const newValue =
          subjectValue.substring(0, startPos) +
          formattedWord +
          subjectValue.substring(endPos, subjectValue.length);
        setSubjectValue(newValue);
      } else {
        const quill = quillRef.current.getEditor();
        const range = quill.selection.savedRange;
        if (range) {
          const position = range.index || 0;
          quill.insertText(position, formattedWord);
        }
      }
      input.focus();
      sendAmplitudeData(TEMPLATE_AMPLITUDE_EVENTS[mode].variable);
    }
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit({
        subject: subjectValue,
        body: bodyValue,
        variables: [],
      });
    } else {
      onCancelClick();
    }
  };

  const handleBodyChange = (value: string) => {
    setBodyValue(value);
    sendAmplitudeData(TEMPLATE_AMPLITUDE_EVENTS[mode].body);
  };

  const handleSubjectChange = (value: string) => {
    setSubjectValue(value);
  };

  const cardClassName = cn("h-full border-gray-200 rounded-xl", {
    border: editMode,
    "bg-gray-50": !editMode,
  });

  const subjectClassName = cn("!bg-white shadow-none border-none", {
    "!bg-white": editMode,
    "!bg-gray-50": !editMode,
  });

  const subjectInputClassName = cn("text-sm font-semibold text-gray-950", {
    "bg-gray-50": !editMode,
  });

  const headerClassName = cn("border-b p-1 pl-4 flex gap-2");

  const footerClassName = cn("flex justify-end items-center", {
    "border-t": editMode,
  });

  return (
    <Card className={cardClassName} shadow="none">
      <CardHeader className={headerClassName}>
        <p className="text-sm text-gray-500 font-light">Subject:</p>
        <Input
          ref={subjectRef}
          classNames={{
            input: subjectInputClassName,
            inputWrapper: subjectClassName,
          }}
          value={subjectValue}
          onValueChange={handleSubjectChange}
          onFocus={() =>
            handleFocus({
              inputRef: subjectRef,
              name: "subject",
            })
          }
        />
      </CardHeader>
      <CardBody className="p-0 h-full">
        <TextEditor
          raw
          value={bodyValue}
          onChange={handleBodyChange}
          disabled={!editMode}
          quillRef={quillRef}
          onFocus={() =>
            handleFocus({
              inputRef: quillRef,
              name: "body",
            })
          }
          handleVariableClick={insertWord}
        />
      </CardBody>
      <CardFooter className={footerClassName}>
        {editMode ? (
          <div className="w-full flex justify-between items-center">
            <Button
              size="sm"
              radius="lg"
              variant="bordered"
              onClick={onCancelClick}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              size="sm"
              radius="lg"
              color="primary"
              onClick={handleSubmit}
            >
              Save changes
            </Button>
          </div>
        ) : (
          <Button
            startContent={<EditIcon />}
            radius="lg"
            color="primary"
            variant="bordered"
            size="sm"
            onClick={onChangeClick}
            className="bg-white"
          >
            Edit
          </Button>
        )}
      </CardFooter>
    </Card>
  );
};
