import { Link } from "react-router-dom";

import clsx from "clsx";

const Footer = () => {
  return (
    <footer className={clsx("bg-white shadow flex flex-auto items-center h-16 max-h-[64px] p-4 sm:px-6 md:px-8 mt-3")}>
      <div className="flex items-center justify-between flex-auto w-full">
        <span>
          Copyright © 2024
          <Link to={`https://loadconnect.io`} className="font-semibold mx-1 underline">
            Carrierify
          </Link>
          All rights reserved.
        </span>
        <div className="mr-[70px]">
          <a className="text-gray" href="https://loadconnect.io/TOC" rel="noreferrer" target="_blank">
            Terms &amp; Conditions
          </a>
          <span className="mx-2 text-muted"> | </span>
          <a className="text-gray" target="_blank" rel="noreferrer" href="https://loadconnect.io/PrivacyPolicy">
            Privacy &amp; Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
