import { RootState } from "@/redux/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

const AutoAuthenticator = () => {
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const authState = useSelector((state: RootState) => state.authReducer.value);

  const accessToken = query.get("accessToken") || "";
  const token = query.get("token") || "";
  const email = query.get("email") || "";

  useEffect(() => {
    if (accessToken || (token && email)) {
      query.set("redirect", query.get("redirect") || location.pathname);
      navigate(`/sign-in/verifying?${query.toString()}`);
    }
  }, [
    accessToken,
    token,
    email,
    navigate,
    query,
    location.pathname,
    authState.isAuth,
  ]);

  return null;
};

export default AutoAuthenticator;
