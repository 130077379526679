import { FC, useCallback, useState } from "react";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";

import { ReactComponent as AddIcon } from "@/assets/icons/add-icon.svg";
import { LoadBoardsSelector } from "@/components/loadboards-selector";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { postUserInfo } from "@/server/auth.service";
import { setUserInfo } from "@/redux/slices/user-info-slice";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";
import { getCookie } from "react-use-cookie";

export const AddNewLoadBoard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.welcomePageAddMoreButtonClicked);
    setIsModalOpen(true);
  };

  return (
    <Button
      onClick={openModal}
      className="w-48 h-28 shadow-none bg-transparent p-4 rounded-lg border border-gray-400 flex justify-center items-center gap-3 border-dashed cursor-pointer [&>*]:text-gray-600 hover:border-brand-700 hover:bg-brand-700/5 [&>*]:hover:text-brand-700"
    >
      <AddIcon />
      <h4 className="text-sm font-normal">Add more</h4>
      <LoadBoardsModal show={isModalOpen} onChange={setIsModalOpen} />
    </Button>
  );
};

const LoadBoardsModal: FC<{
  show: boolean;
  onChange: (e: boolean) => void;
}> = ({ show, onChange }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector((state: RootState) => state.userInfoReducer);

  const [selectedLoadboards, setSelectedLoadboards] = useState<string[]>(userInfo?.loadboards);

  const handleSelectionChange = useCallback((e: string[]) => {
    sendAmplitudeData(AMPLITUDE_EVENTS.welcomePageLoadboardsListChanged);
    setSelectedLoadboards(e);
  }, []);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const newUserInfo = {
      ...userInfo,
      loadboards: selectedLoadboards,
      source: getCookie("source"),
    };

    const data = await postUserInfo(newUserInfo);
    dispatch(setUserInfo(data));

    setLoading(false);
    onChange(false);
    sendAmplitudeData(AMPLITUDE_EVENTS.welcomePageUpdateLoadboardsModalSaveButtonClicked);
  }, [dispatch, onChange, selectedLoadboards, userInfo]);

  const handleCancel = useCallback(() => {
    setSelectedLoadboards(userInfo.loadboards);
    onChange(false);
    sendAmplitudeData(AMPLITUDE_EVENTS.welcomePageUpdateLoadboardsModalCancelButtonClicked);
  }, [onChange, userInfo.loadboards]);

  return (
    <Modal size="xl" isOpen={show} onOpenChange={onChange}>
      <ModalContent>
        <ModalHeader className="text-xl font-semibold text-gray-900 p-5">Update the list of your load boards</ModalHeader>
        <ModalBody className="p-5 flex gap-2 flex-wrap">
          <LoadBoardsSelector value={selectedLoadboards} onChange={handleSelectionChange} />
        </ModalBody>
        <ModalFooter className="p-5 w-full flex justify-between">
          <Button variant="bordered" onClick={handleCancel}>
            Cancel
          </Button>
          <Button isLoading={loading} onClick={handleSubmit} color="primary">
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
