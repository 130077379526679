"use client";

import { EditIcon, TrashIcon } from "@/components/icons";
import { Template } from "@/types";
import { Button } from "@nextui-org/button";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/table";
import DeleteButton from "@/components/delete-button";
import { Link, useNavigate } from "react-router-dom";
import { Skeleton } from "@nextui-org/react";

const TemplateList = ({
  templates,
  onDelete,
  loading,
  ...props
}: {
  templates: Template[];
  loading: boolean;
  onDelete: Function;
  className?: string;
  props?: any;
}) => {
  const navigate = useNavigate();

  const redirectToEditor = (template: Template) =>
    navigate(`/emailTemplateEditor/${template.id}`);

  return (
    <Table
      shadow="none"
      classNames={{
        wrapper: "p-0",
        tr: "cursor-pointer hover:bg-gray-100 rounded",
        td: "p-4 text-black",
        th: "p-4",
      }}
      aria-label="Template List"
      {...props}
    >
      <TableHeader>
        <TableColumn className="text-sm text-center">ID</TableColumn>
        <TableColumn className="text-sm text-center">Name</TableColumn>
        <TableColumn className="text-sm text-center">Subject</TableColumn>
        <TableColumn className="text-sm text-center">Action</TableColumn>
      </TableHeader>
      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell>
              <Skeleton className="h-3 w-3/5 rounded-lg mx-auto" />
            </TableCell>
            <TableCell>
              <Skeleton className="h-3 w-3/5 rounded-lg mx-auto" />
            </TableCell>
            <TableCell>
              <Skeleton className="h-3 w-3/5 rounded-lg mx-auto" />
            </TableCell>
            <TableCell>
              <div className="flex gap-2 justify-center">
                <Skeleton className="h-3 w-1/5 rounded-lg" />
                <Skeleton className="h-3 w-1/5 rounded-lg" />
              </div>
            </TableCell>
          </TableRow>
        ) : (
          templates.map((template, key) => (
            <TableRow key={key}>
              <TableCell className="text-center">{template.id}</TableCell>
              <TableCell
                className="text-center"
                onClick={() => redirectToEditor(template)}
              >
                {template.name}
              </TableCell>
              <TableCell
                className="text-center"
                onClick={() => redirectToEditor(template)}
              >
                {template.subject}
              </TableCell>
              <TableCell className="text-center">
                <Button
                  isIconOnly
                  variant="light"
                  as={Link}
                  to={`/emailTemplateEditor/${template.id}`}
                >
                  <EditIcon size={20} />
                </Button>

                <DeleteButton
                  content="If you delete your template, all the content within it will be permanently lost."
                  onDelete={() => onDelete && onDelete(template)}
                >
                  <TrashIcon size={20} />
                </DeleteButton>
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export default TemplateList;
