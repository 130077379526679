import React, { FC } from "react";
import ReactQuill from "react-quill";
import debounce from "lodash.debounce";
import "react-quill/dist/quill.snow.css";
import { Variable } from "./components/Variable";
import "./index.css";
import { cn } from "@nextui-org/react";

interface IProps {
  value?: string;
  onChange?: (e: string) => void;
  raw?: boolean;
  disabled?: boolean;
  quillRef: React.RefObject<any>;
  onFocus?: () => void;
  handleVariableClick: (key: string) => void;
}

const EMAIL_DATA: Record<string, string> = {
  origin: "Origin",
  dest: "Destination",
  email: "Email",
  trip: "Trip",
  avail: "Pickup Date",
  rate: "Rate",
  ref: "Reference Number",
  company: "Company Name",
  do: "Deadhead",
  truck: "Truck Type",
};

export const TextEditor: FC<IProps> = ({
  value,
  onChange,
  raw = false,
  disabled = false,
  quillRef,
  onFocus,
  handleVariableClick,
}) => {
  const debouncedHandleChange = debounce(
    (value: string) => onChange && onChange(value),
    500
  );

  const className = cn("w-full h-full flex flex-col", {
    border: !raw,
  });

  const editorClassName = cn("text-editor", {
    disabled: disabled,
  });

  return (
    <div className={className}>
      <div className={editorClassName}>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={value}
          onChange={debouncedHandleChange}
          className="w-full h-full overflow-auto flex flex-col text-gray-800"
          onFocus={onFocus}
        />
      </div>
      {!disabled && (
        <div className="shrink-0 p-3 flex flex-col gap-2">
          <h3 className="text-sm text-gray-500 font-medium">
            Select variables
          </h3>
          <div className="flex flex-wrap gap-2">
            {Object.keys(EMAIL_DATA).map(
              (key) =>
                !!EMAIL_DATA[key] && (
                  <Variable
                    key={key}
                    label={EMAIL_DATA[key]}
                    onClick={() => handleVariableClick(key)}
                  />
                )
            )}
          </div>
        </div>
      )}
    </div>
  );
};
