import { ITutorial } from "@/types";

const Tutorial1 = () => {
  return (
    <>
      <p>
        If you're not seeing the LoadConnect features on your LoadBoard page
        (DAT, LoadLink, Truckstop), here are some simple steps to help:
      </p>
      <p>1. Pin your LoadConnect extension.</p>
      <img src="img/tutorials/1/1.png" width={408} height={195} alt="img" />
      <p>2. Ensure all features in the settings are turned on.</p>
      <img src="img/tutorials/1/2.png" width={518} height={516} alt="img" />
      <p>
        3. Open your LoadBoard in the browser tab (DAT, TruckStop, LoadLink or
        Sylectus).
      </p>
      <p>4. Refresh your LoadBoard page and try to send an email.</p>
      <p>
        5. Finally, check your sent messages to confirm that the email was
        successfully sent.
      </p>
      <img src="img/tutorials/1/3.png" width={439} height={206} alt="img" />
    </>
  );
};

const Tutorial2 = () => {
  return (
    <>
      <p>How to create templates:</p>
      <p>
        1. Start by going to your{" "}
        <a
          href="https://app.loadconnect.io/emailTemplate"
          target="_blank"
          rel="noreferrer"
        >
          LoadConnect Dashboard
        </a>
        .
      </p>
      <p>2. Select the "Templates" section.</p>
      <img src="img/tutorials/2/1.png" width={1215} height={400} alt="img" />
      <p>
        3. Create as many templates as you need. For example, if you need to add
        different MC numbers to each template.
      </p>
      <img src="img/tutorials/2/2.png" width={752} height={445} alt="img" />
      <p>
        4. Next, go to your LoadBoard page and click on the LoadConnect
        extension button.
      </p>
      <img src="img/tutorials/2/3.png" width={505} height={592} alt="img" />
      <p>
        5.1 Here, you can choose from your templates whenever you send an email.
      </p>
      <p>
        5.2 Or you can switch between your email addresses using the Popup
        feature:
      </p>
      <img src="img/tutorials/2/4.png" width={304} height={170} alt="img" />
      <img src="img/tutorials/2/5.png" width={855} height={227} alt="img" />
      <p>6. Now, you're ready to send emails using your prepared templates.</p>
      <p>
        You can also watch our{" "}
        <a
          href="https://www.youtube.com/watch?v=cJfjPNvLis0"
          target="_blank"
          rel="noreferrer"
        >
          YouTube tutorial
        </a>{" "}
        for adding templates and signatures to your emails.
      </p>
    </>
  );
};

const Tutorial3 = () => {
  return (
    <>
      <p>
        If you're encountering issues with sending emails using our extension,
        here are some steps to troubleshoot:
      </p>
      <p>1. Make sure you have signed in using the correct email address.</p>
      <p>
        2. Check if you have verified your email address. You can open “My
        Account” from this link.
      </p>
      <p>
        3. If the email status is marked red, make sure you click on the
        “Verify” button and proceed with the verification. The status should be
        green saying "Verified"
      </p>
      <img src="img/tutorials/3/1.png" width={275} height={196} alt="img" />
      <p>
        4. If you have signed in with Gmail, you need to sign out and sign back
        in, ensuring you have clicked on the "Send emails on your behalf"
        checkbox.
      </p>
      <img src="img/tutorials/3/2.png" width={394} height={539} alt="img" />
      <p>
        5. Open your LoadBoard in the browser tab (DAT, TruckStop, LoadLink or
        Sylectus)
      </p>
      <p>
        6. Verify that all features in the settings are turned on and set up
        your email template by clicking on the LoadConnect extension button on
        the top right of your browser.
      </p>
      <img src="img/tutorials/3/3.png" width={462} height={363} alt="img" />
      <p>7. Refresh your Load Board page.</p>
      <img src="img/tutorials/3/4.png" width={118} height={42} alt="img" />
      <p>8. Try to send an email.</p>
      <img src="img/tutorials/3/5.png" width={439} height={206} alt="img" />
      <p>
        9. Check your email page for sent emails to confirm successful sending.
      </p>
      <p>Hope this helps!</p>
    </>
  );
};

const Tutorial4 = () => {
  return (
    <>
      <p>
        If you are using an email address that was not provided by Gmail or
        Outlook, you can set it up using our SMTP settings:
      </p>
      <p>
        1. Open “My Account” on the LoadConnect Dashboard or follow this{" "}
        <a
          href="https://app.loadconnect.io/email"
          target="_blank"
          rel="noreferrer"
        >
          link
        </a>
      </p>
      <p>2. Click on “Add New Service” and choose the “Connect SMTP” option</p>
      <img src="img/tutorials/4/1.png" width={1008} height={280} alt="img" />
      <p>
        3. Check your email provider's SMTP settings and insert them in the box.
        You can google them (Yahoo, AOL, Office 365, Zoho, GMX, etc.)
      </p>
      <img src="img/tutorials/4/2.png" width={503} height={428} alt="img" />
      <p>
        4. If the status is Green “Verified” you are done and now will be able
        to send emails.
      </p>
      <img src="img/tutorials/4/3.png" width={275} height={196} alt="img" />
      <div>
        <h5>Here are examples of popular email providers' SMTP settings</h5>
        <div>
          <small>
            <b>Yahoo</b>
            <table>
              <tr>
                <td>
                  <b>Email</b>
                </td>
                <td>Type in your email address</td>
              </tr>
              <tr>
                <td>
                  <b>Password</b>
                </td>
                <td>
                  You need to create a third-party application password. Follow
                  this{" "}
                  <a
                    href="https://help.yahoo.com/kb/SLN15241.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    link
                  </a>{" "}
                  to create one.
                </td>
              </tr>
              <tr>
                <td>
                  <b>Server Host</b>
                </td>
                <td>smtp.mail.yahoo.com</td>
              </tr>
              <tr>
                <td>
                  <b>Port</b>
                </td>
                <td>465 (with SSL) or 587</td>
              </tr>
            </table>
          </small>
        </div>
        <div>
          <small>
            <b>AOL</b>
            <table>
              <tr>
                <td>
                  <b>Email</b>
                </td>
                <td>Type in your email address</td>
              </tr>
              <tr>
                <td>
                  <b>Password</b>
                </td>
                <td>Type in the password for your email address</td>
              </tr>
              <tr>
                <td>
                  <b>Server Host</b>
                </td>
                <td>smtp.aol.com</td>
              </tr>
              <tr>
                <td>
                  <b>Port</b>
                </td>
                <td>465 (with SSL) or 587</td>
              </tr>
            </table>
          </small>
        </div>

        <div>
          <small>
            <b>Office 365</b>
            <table>
              <tr>
                <td>
                  <b>Email</b>
                </td>
                <td>Type in your email address</td>
              </tr>
              <tr>
                <td>
                  <b>Password</b>
                </td>
                <td>Type in the password for your email address</td>
              </tr>
              <tr>
                <td>
                  <b>Server Host</b>
                </td>
                <td>smtp.office365.com</td>
              </tr>
              <tr>
                <td>
                  <b>Port</b>
                </td>
                <td>587 or 25</td>
              </tr>
            </table>
          </small>
        </div>

        <div>
          <small>
            <b>Zoho</b>
            <table>
              <tr>
                <td>
                  <b>Email</b>
                </td>
                <td>Type in your email address</td>
              </tr>
              <tr>
                <td>
                  <b>Password</b>
                </td>
                <td>Type in the password for your email address</td>
              </tr>
              <tr>
                <td>
                  <b>Server Host</b>
                </td>
                <td>smtppro.zoho.com or smtp.zoho.com</td>
              </tr>
              <tr>
                <td>
                  <b>Port</b>
                </td>
                <td>465 (with SSL) or 587</td>
              </tr>
            </table>
          </small>
        </div>

        <div>
          <small>
            <b>Mozilla Thunderbird</b>
            <table>
              <tr>
                <td>
                  <b>Email</b>
                </td>
                <td>Type in your email address</td>
              </tr>
              <tr>
                <td>
                  <b>Password</b>
                </td>
                <td>Type in the password for your email address</td>
              </tr>
              <tr>
                <td>
                  <b>Server Host and Port</b>
                </td>
                <td>
                  Check in your Thunderbird settings. You can refer to these{" "}
                  <a
                    href="https://support.mozilla.org/en-US/products/thunderbird"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guides
                  </a>
                  .
                </td>
              </tr>
            </table>
          </small>
        </div>

        <div>
          <small>
            <b>GMX</b>
            <table>
              <tr>
                <td>
                  <b>Email</b>
                </td>
                <td>Type in your email address</td>
              </tr>
              <tr>
                <td>
                  <b>Password</b>
                </td>
                <td>Type in the password for your email address</td>
              </tr>
              <tr>
                <td>
                  <b>Server Host</b>
                </td>
                <td>smtp.gmx.com</td>
              </tr>
              <tr>
                <td>
                  <b>Port</b>
                </td>
                <td>465 (with SSL), 587, or 25</td>
              </tr>
            </table>
          </small>
        </div>
      </div>
      <p>
        If you did not find your company email provider on this list, you can
        search for the settings in your email settings or google them.
      </p>
      <p>
        Alternatively, you can reach out to our support team to help you set up
        your company email.
      </p>
    </>
  );
};

// TUTORIALS

export const TUTORIALS: ITutorial[] = [
  {
    id: 1,
    title:
      "LoadConnect.io - is the ultimate Google Chrome extension for Carriers and Dispatchers!",
    video: "https://www.youtube.com/embed/74Wi-lQciLE?si=XwwhYDEj0yRJKLI6",
    big: true,
  },
  {
    id: 2,
    title: "Extension is not showing on load boards",
    description: "Get to know how to resolve this issue",
    icon: "img/emojis/sad-emoji.png",
    fullDescription: <Tutorial1 />,
  },
  {
    id: 3,
    title: "How to add and switch templates",
    description: "Create templates for various purposes",
    icon: "img/emojis/template-emoji.png",
    fullDescription: <Tutorial2 />,
  },
  {
    id: 4,
    title: "Emails are not sending with LoadConnect",
    description: "That’s easy to fix, check how you can do it",
    icon: "img/emojis/tears-emoji.png",
    fullDescription: <Tutorial3 />,
  },
  {
    id: 5,
    title: "How to add SMTP settings for your company email",
    description: "Company email not from Gmail or Outlook? We got you!",
    icon: "img/emojis/inbox-emoji.png",
    fullDescription: <Tutorial4 />,
  },
];
