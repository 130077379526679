import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  DropdownSection,
  Avatar,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { logOut } from "@/redux/slices/auth-slice";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";

import { ReactComponent as ChevronDownIcon } from "@/assets/icons/chevron-down-icon.svg";

const UserDetail = ({ small = false }: { small?: boolean }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email } = useSelector((state: RootState) => state.authReducer.value);

  const onLogout = () => {
    dispatch(logOut());
    sendAmplitudeData(AMPLITUDE_EVENTS.dashboardAccountLoggedOut);
    navigate("/sign-in");
  };

  const firstLetter = email.substring(0, 1).toUpperCase();

  return (
    <Dropdown placement="bottom-start">
      <DropdownTrigger aria-label="user-dropdown">
        {small ? (
          <Avatar
          fallback={
            <span className="text-sm text-white">{firstLetter}</span>
          }
          classNames={{
            base: "bg-brand-700 p-0",
          }}
        />
        ) : (
          <div className="flex gap-3 cursor-pointer items-center aria-expanded:scale-100 aria-expanded:opacity-100">
            <Avatar
              fallback={
                <span className="text-xs text-brand-700">{firstLetter}</span>
              }
              classNames={{
                base: "bg-brand-700/10 p-0 w-8 h-8",
              }}
            />
            <span className="text-sm text-gray-700">{email}</span>
            <ChevronDownIcon />
          </div>
        )}
      </DropdownTrigger>
      <DropdownMenu aria-label="User Actions" variant="flat">
        <DropdownSection showDivider>
          <DropdownItem key="profile" className="h-14 gap-2">
            <p>Signed in as</p>
            <p>{email}</p>
          </DropdownItem>
        </DropdownSection>
        <DropdownItem
          key="logout"
          color="danger"
          className="text-danger"
          onClick={onLogout}
        >
          Log Out
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default UserDetail;
