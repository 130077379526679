import React from 'react'

const ToastContent = (message: string | undefined) => {
  return (
    <div>
      {message}
    </div>
  )
}

export default ToastContent
