import { WarningIcon } from '@/components/icons'
import { Button } from '@nextui-org/button'
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalBody } from '@nextui-org/react'

const Notification = ({
    title, content, onClose, isOpen
}: {
    title?: string,
    content?: string,
    onClose: any,
    isOpen: boolean
}) => {

    return (
        <Modal isOpen={isOpen}>
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <div className='flex gap-6 items-center'>
                        <div className='text-danger'>
                            <WarningIcon size={54} />
                        </div>
                        <div className='flex flex-col gap-1'>
                            <h3>
                                {title ? title : "You can't delete this record"}
                            </h3>
                            <p>
                                {content ? content : "You will need to have at least one active record"}
                            </p>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className='data-[pressed=true]:scale-100' aria-label='close' color='danger' variant='light' onClick={onClose}>Go Back</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default Notification
