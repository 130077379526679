import { FC, useMemo } from "react";
import { Card, Link } from "@nextui-org/react";
import { ReactComponent as LinkIcon } from "@/assets/icons/link-icon.svg";
import { LOAD_BOARDS } from "@/constants/loadboards";

import { AddNewLoadBoard } from "./AddLoadBoard";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";

export const MyLoadBoards = () => {
  const loadboards = useSelector(
    (state: RootState) => state.userInfoReducer.loadboards
  );

  const filterLoadboards = useMemo(
    () => LOAD_BOARDS.filter((board) => loadboards?.includes(board.id)),
    [loadboards]
  );

  return (
    <div className="w-full flex flex-col gap-5 p-8 pt-5">
      <h2 className="text-xl font-medium">My load boards</h2>
      <div className="w-ful flex-wrap flex gap-2 ">
        {filterLoadboards.map((board) => (
          <LoadBoard key={board.id} data={board} />
        ))}
        <AddNewLoadBoard />
      </div>
    </div>
  );
};

const LoadBoard: FC<{ data?: Record<string, string> }> = ({ data }) => {
  const sendAnalytics = () =>
    sendAmplitudeData(AMPLITUDE_EVENTS.welcomePageLoadboardClicked);

  return (
    <Link
      onClick={sendAnalytics}
      href={data?.href}
      target="_blank"
      className="w-48 h-28 rounded-lg"
    >
      <Card className="w-full h-full shadow-none bg-gray-50 px-4 py-3 rounded-lg relative hover:bg-white hover:shadow-md">
        <div className="w-full h-full flex flex-col justify-end items-center gap-1">
          <img
            className="h-16 w-40 object-contain"
            src={data?.icon}
            alt={data?.name}
          />
          <h4 className="text-sm font-normal">{data?.name}</h4>
        </div>

        <LinkIcon className="absolute top-2 right-2" />
      </Card>
    </Link>
  );
};
