"use client";

import TemplateViewer from "@/components/template-viewer";
import {
  addTemplate,
  getTemplateById,
  updateTemplate,
} from "@/server/template.service";
import { Template } from "@/types";
import { Button } from "@nextui-org/button";
import { useEffect, useState } from "react";
import Loading from "@/components/loading";
import ToastMessage from "@/components/toast";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";

const CustomPage = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState({ name: "", subject: "" });
  const [template, setTemplate] = useState<Template>({
    cc: "",
    id: -1,
    labels: [],
    name: "",
    subject: "",
    template: "",
  });

  const params: any = useParams();

  useEffect(() => {
    if (params.id) {
      setLoad(true);
      getTemplateById(params.id)
        .then((resp) => {
          setTemplate(resp);
          setLoad(false);
        })
        .catch(() => {
          setLoad(false);
        });
    }
  }, [params.id]);

  const onSubmit = async () => {
    let message = { name: "", subject: "" };

    if (!template.name)
      message = { ...message, name: "Please enter template name" };

    if (!template.subject)
      message = { ...message, subject: "Please enter template subject" };

    if (Object.values(message).join("") !== "") return setErrorMessage(message);

    setIsSubmitting(true);

    if (params.id) {
      try {
        await updateTemplate(template);
        ToastMessage("success", "Update Success");
        navigate("/emailTemplate");
      } catch (error) {
        ToastMessage("error", "Error while updating");
      }
    } else {
      try {
        await addTemplate(template);
        ToastMessage("success", "Success creating a new Template");
        sendAmplitudeData(AMPLITUDE_EVENTS.dashboardNewTemplateCreated);
        navigate("/emailTemplate");
      } catch (error) {
        ToastMessage("error", "Error while saving");
      }
    }
    setIsSubmitting(false);
  };

  return (
    <div className="flex-grow px-8 py-6">
      <Helmet>
        <title>LoadConnect Email Template Editor</title>
      </Helmet>
      {load && (
        <div className="absolute top-0 left-0 z-30 bg-white w-full h-full flex justify-center items-center">
          <Loading />
        </div>
      )}
      <TemplateViewer
        template={template}
        onChange={(val: Template) => setTemplate(val)}
        onLoad={() => setLoad(false)}
        errorMessage={errorMessage}
      />

      <div className="grid grid-cols-3 gap-4 mt-7">
        <div className="col-span-2 flex gap-4 justify-end">
          <Button
            color="primary"
            as={Link}
            className="data-[pressed=true]:scale-100 hover:bg-primary-600"
            to="/emailTemplate"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={onSubmit}
            className="data-[pressed=true]:scale-100 hover:bg-primary-600"
            isLoading={isSubmitting}
          >
            {params.id ? "Update" : "Save"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomPage;
