import { CrossIcon, XMarkIcon } from '@/components/icons'
import { getReviews } from '@/server/mcReview.service'
import { McReview } from '@/types'
import { Button, Divider } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import Summary from './summary'

const ReviewsDialog = ({
    company,
    mc,
    onClose
}: {
    company?: string,
    mc?: string,
    onClose?: any
}) => {

    const [reviews, setReviews] = useState<McReview[]>([])

    useEffect(() => {
        if (!mc) return;

        const func = async () => {
            const data: McReview[] = await getReviews(mc)
            setReviews(data)
        }

        func()
    }, [mc])


    return (
        <div className='w-512 py-7 px-8 flex flex-col gap-6 text-black'>
            <div className='flex justify-between'>
                <h4>Reviews</h4>
                <Button isIconOnly color='primary' radius='full' variant='flat' className='w-6 h-6 min-w-6' onClick={() => onClose && onClose()}>
                    <XMarkIcon size={16} />
                </Button>
            </div>
            <div className='text-base'>
                <p className='text-bold'>{company}</p>
                <p>TQL/MC#054357</p>
            </div>

            <div>
                <p className='m-0 font-semibold text-base mb-4'>Rating Snapshot</p>
                <Summary reviews={reviews} />
            </div>

            <Divider />

            <Button
                color='primary'
                endContent={<CrossIcon />}
                className='data-[pressed=true]:scale-100'
            >
                Add Review
            </Button>
        </div>
    )
}

export default ReviewsDialog