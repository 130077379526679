import { LoadInfo } from "@/types";
import { callLoadConnectApi, callRefLoadApi } from "./api.service";
import { SearchBoxSuggestionResponse } from "@mapbox/search-js-core";

export async function getLoadInfos(): Promise<LoadInfo[]> {
  const resp = await callLoadConnectApi("/loadinfos", "get", true, {});
  const info: LoadInfo[] = resp.data;
  return info;
}

export async function getLoadTable(idAry: string[]): Promise<LoadInfo[]> {
  let query = "";
  idAry.map((id) => (query += `where[id][in]=${id}&`));
  query = query.substring(0, query.length - 1);

  const resp = await callLoadConnectApi(`/loadinfos?${query}`, "get", true, {});
  const info: LoadInfo[] = resp.data;
  return info;
}

export async function getLoadInfo(id: string): Promise<LoadInfo> {
  const resp = await callLoadConnectApi(`/loadinfos/${id}`, "get", true, {});
  const info: LoadInfo = resp.data;
  return info;
}

export async function patchLoadInfo(info: LoadInfo): Promise<LoadInfo> {
  const resp = await callLoadConnectApi(
    `/loadinfos/${info.id}`,
    "patch",
    true,
    info
  );
  const result: LoadInfo = resp.data;
  return result;
}

export async function getSearchResult(
  query: string,
  sessionToken: string
): Promise<SearchBoxSuggestionResponse> {
  const resp = await callRefLoadApi(
    `/search?q=${query}&language=en&country=US&session_token=${sessionToken}&types=city,region,postcode&access_token=pk.eyJ1IjoidXNhbWFrb3YiLCJhIjoiY2tkZThhNTZyMjBqMTM0azY0bmEzc3VweSJ9.-Esyr8LgnJYyeiggTsauRA`,
    "get",
    false,
    {}
  );
  const result: SearchBoxSuggestionResponse = resp.data;
  return result;
}
