import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { useLocation } from "react-router-dom";
import { useDisclosure } from "@nextui-org/react";
import AuthModal from "../auth-modal";

const GuestLayout = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const location = useLocation();
  const { isAuth } = useSelector((state: RootState) => state.authReducer.value);

  const isMapPage = location.pathname.startsWith("/loadinfo");

  const onRedirect = (url: string) => {
    if (!isAuth) onOpen();
  };

  return (
    <main className="flex bg-white overflow-hidden">
      <Sidebar isAuth={isAuth} onRedirect={onRedirect} />
      <section className="relative flex flex-grow flex-col h-screen overflow-auto">
        <Header isMapPage={isMapPage} isAuth={isAuth} />
        <Outlet />
        {!isMapPage && <Footer />}
      </section>

      <AuthModal isOpen={isOpen} onClose={onClose} />
    </main>
  );
};

export default GuestLayout;
