import { AcademicCapIcon, ArrowNarrowRightIcon } from '@/components/icons'
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react'

const TryModal = () => {
    return (
        <Modal
            isOpen={false}
            size='lg'
            classNames={{
                closeButton: "bg-gray-100 text-primary"
            }}
        >
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalBody className='flex flex-row gap-4 items-center px-6'>
                    <div className='m-4'>
                        <AcademicCapIcon />
                    </div>
                    <div>
                        <h1 className='font-semibold text-xl text-black'>Wanna try LoadConnect?</h1>
                        <p>Search, drive, earn and share with your friends</p>
                    </div>
                </ModalBody>
                <ModalFooter className='mb-2 px-6 flex gap-6'>
                    <Button
                        color='primary'
                        className='w-full data-[pressed=true]:scale-100 text-white'
                        startContent={<ArrowNarrowRightIcon />}
                    >
                        Start Free
                    </Button>
                    <Button
                        color='primary'
                        variant='bordered'
                        className='w-full data-[pressed=true]:scale-100'
                    >
                        Contact Us
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default TryModal