import {
  API_CARRIERIFY_PREFIX,
  API_LOADCONNECT_PREFIX,
  PERSIST_STORE_NAME,
  REQUEST_HEADER_AUTH_KEY,
  TOKEN_TYPE,
} from "@/config/app.config";
import {
  logOut,
  setAccessToken,
  setRefreshToken,
} from "@/redux/slices/auth-slice";
import { store } from "@/redux/store";
import axios from "axios";
import deepParseJson from "@/utils/deepParseJson";

const ApiService = axios.create({
  timeout: 60000,
});

ApiService.interceptors.request.use(
  (config) => {
    const { authReducer } = store.getState();
    let rawPersistData = null;
    try {
      rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
    } catch (error) {}

    const persistData = deepParseJson(rawPersistData);
    let accessToken = persistData?.authReducer?.value?.accessToken;
    if (authReducer.value.accessToken) {
      accessToken = authReducer.value.accessToken;
    }

    if (accessToken) {
      config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ApiService.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    const { response, config } = error;
    if (response && response.status === 401) {
      const { authReducer } = store.getState();
      // store.dispatch(logOut())
      // get accessToken from refreshToken
      try {
        const resp2 = await fetch(
          "https://api.carrierify.com/auth/refresh?refreshToken=" +
            authReducer.value.refreshToken,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authReducer.value.accessToken}`,
            },
          }
        );

        if (resp2.status === 200) {
          const dataresp = await resp2.json();
          store.dispatch(setAccessToken(dataresp.accessToken));
          store.dispatch(setRefreshToken(dataresp.refreshToken));

          return Promise.resolve();
        } else {
          throw new Error("error");
        }
      } catch (error) {
        delete config.headers[REQUEST_HEADER_AUTH_KEY];
        store.dispatch(logOut());
      }
      return Promise.reject({ ...error, config });
    }
  }
);

export async function callApi(
  url: string,
  method: string,
  useAuth: boolean,
  data: any
) {
  //todo redux accessToken and post

  const resp = await ApiService.request({
    url,
    method,
    baseURL: API_CARRIERIFY_PREFIX,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });

  return resp;
}

export async function callLoadConnectApi(
  url: string,
  method: string,
  useAuth: boolean,
  data: any
) {
  //todo redux accessToken and post

  const resp = await ApiService.request({
    url,
    method,
    baseURL: API_LOADCONNECT_PREFIX,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });

  return resp;
}

export async function callRefLoadApi(
  url: string,
  method: string,
  useAuth: boolean,
  data: any
) {
  const resp = await ApiService.request({
    url,
    method,
    baseURL: "https://ref.loadconnect.io",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
  return resp;
}

export const getTollApi = async (polyline: string) => {
  const data = await fetch("https://ref.loadconnect.io/toll", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ polyline }),
  });
  const resp = await data.json();
  return resp;
};
