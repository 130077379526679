import { Card, CardBody } from "@nextui-org/card";
import { Outlet } from "react-router-dom";
import AutoAuthenticator from "@/components/auto-authenticator";

import { ReactComponent as WhiteLogo } from "@/assets/svg/white-logo.svg";
import { ReactComponent as ColoredLogo } from "@/assets/svg/colored-logo.svg";
import { ReactComponent as AmazonLogo } from "@/assets/svg/amazon-logo.svg";
import { ReactComponent as ChromeStoreLogo } from "@/assets/svg/chrome-store-logo.svg";
import { ReactComponent as G2Logo } from "@/assets/svg/g2-logo.svg";
import { ReactComponent as StarIcon } from "@/assets/icons/star-icon.svg";
import { FC, ReactNode } from "react";

export const SignInLayout = () => {
  return (
    <section className="w-full h-full bg-brand-900 bg-sign-in bg-center bg-cover bg-blend-multiply flex justify-center items-center">
      <AutoAuthenticator />
      <div className="h-full max-h-[1000px] w-[98%] max-w-[1240px] mx-auto py-28 flex justify-between items-center gap-10">
        <div className="h-full flex flex-col justify-between max-w-[602px] text-white">
          <div className="flex flex-col gap-5">
            <WhiteLogo />
            <h1 className="text-5xl leading-[60px] font-bold text-white">
              Simple, secure and free extension for dispatchers
            </h1>
          </div>
          <div>
            <p className="text-5xl leading-3 font-bold">“</p>
            <p className="text-2xl leading-8">
              This extension can help you{" "}
              <span className="text-[26px] font-semibold">
                make your life easy
              </span>{" "}
              as a truck dispatcher.
            </p>
            <div className="flex items-center gap-2 mt-3 mb-8">
              <AmazonLogo />
              <p className="text-lg font-semibold">Amazon</p>
            </div>
            <div className="flex gap-8">
              <Stats logo={<ChromeStoreLogo />} rate={4.9} />
              <Stats logo={<G2Logo />} rate={4.9} />
            </div>
          </div>
        </div>
        <Card className="bg-white w-[454px] h-auto rounded-3xl shrink-0">
          <CardBody className="w-full h-full flex flex-col gap-5 items-center p-12">
            <ColoredLogo />
            <Outlet />
          </CardBody>
        </Card>
      </div>
    </section>
  );
};

const Stats: FC<{ logo: ReactNode; rate: number }> = ({ logo, rate }) => {
  return (
    <div className="flex items-center gap-3">
      {logo}
      <div className="flex gap-2 items-center">
        <p className="text-lg">{rate}</p>
        <div className="flex items-center gap-[2px]">
          {Array(Math.ceil(rate))
            .fill(1)
            .map((_, i) => (
              <StarIcon key={i} />
            ))}
        </div>
      </div>
    </div>
  );
};
