import { Account, AccountCreateDto, AccountMessage } from "@/types";
import { callApi } from "./api.service";



export async function getAccounts(): Promise<Account[]> {
    const resp = await callApi('/account', 'get', true, {});
    const accounts: Account[] = resp.data;
    return accounts;
}

export async function deleteAccount(accountId: number): Promise<Account[]> {
    const resp = await callApi(`/account?accountId=${accountId}`, 'delete', true, {});
    const accounts: Account[] = resp.data;
    return accounts;
}

export async function createAccount(account: AccountCreateDto): Promise<AccountMessage> {
    const resp = await callApi('/account/v1', 'post', true, account);
    const accountMessage: AccountMessage = resp.data;
    return accountMessage;
}
