import { Chip } from "@nextui-org/react";
import { FC } from "react";

interface IProps {
  label: string;
  onClick: () => void;
}

export const Variable: FC<IProps> = ({ label, onClick }) => {
  return (
    <Chip
      classNames={{
        base: "border-1 border-brand-700/20 bg-brand-700/10 cursor-pointer hover:bg-brand-700/20",
        content:
          "text-brand-700 text-sm font-medium p-0 first-letter:uppercase",
      }}
      color="primary"
      radius="lg"
      variant="flat"
      onClick={onClick}
    >
      {label}
    </Chip>
  );
};
