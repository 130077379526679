import { FC } from "react";
import { ReactComponent as LogoIcon } from "@/assets/svg/logo-icon.svg";
import { ReactComponent as LogoName } from "@/assets/svg/logo-name.svg";
import { cn } from "@nextui-org/react";

const SidebarLogo: FC<{ isOpen: boolean; onClick: () => void }> = ({
  isOpen,
  onClick,
}) => {
  return (
    <div onClick={onClick} className="flex w-[160px]cursor-pointer">
      <LogoIcon className={cn("h-8", !isOpen && "cursor-pointer")} />
      {isOpen && <LogoName className="h-8" />}
    </div>
  );
};

export default SidebarLogo;
