import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Avatar, DropdownSection } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'

const LoginHeader = ({ small = false }: { small?: boolean }) => {

    const navigate = useNavigate()

    const onLogin = () => {
        navigate('/sign-in')
    }

    return (
        <Dropdown placement="bottom-start">
            <DropdownTrigger aria-label='user-dropdown'>
                {
                    small ? (
                        <Avatar src='/img/avatars/avatar.png' showFallback className='text-white cursor-pointer shadow-md' />
                    ) : (
                        <div className='flex gap-2 cursor-pointer items-center aria-expanded:scale-100 bg-white px-3 py-1.5 rounded aria-expanded:opacity-100'>
                            <Avatar src='/img/avatars/avatar.png' showFallback className='text-white' />
                        </div>
                    )
                }
            </DropdownTrigger>
            <DropdownMenu aria-label="User Actions" variant="flat">
                <DropdownSection showDivider >
                    <DropdownItem key="profile" className="h-14 gap-2">
                        <p>Signed in as Guest</p>
                    </DropdownItem>
                </DropdownSection>
                <DropdownItem key="login" color="danger" className='text-danger' onClick={onLogin}>
                    Log In
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}

export default LoginHeader
