import { Account } from "@/types";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/table";
import {
  GMailIcon,
  OutlookIcon,
  SMTPIcon,
  TrashIcon,
} from "@/components/icons";
import DeleteButton from "@/components/delete-button";
import { Button } from "@nextui-org/button";
import { Skeleton } from "@nextui-org/react";

const TableWrapper = ({
  accounts,
  onDelete,
  onVerify,
  loading,
  ...props
}: {
  accounts: Account[];
  className: string;
  loading?: boolean;
  onDelete?: any;
  onVerify?: any;
}) => {
  return (
    <Table
      shadow="none"
      classNames={{
        wrapper: "p-0",
        tr: "cursor-pointer hover:bg-gray-100 hover:rounded",
        td: "p-4 text-gray-900",
        th: "p-4",
      }}
      aria-label="Email Table"
      {...props}
    >
      <TableHeader>
        <TableColumn className="text-sm text-center">Email Service</TableColumn>
        <TableColumn className="text-sm text-center">Name</TableColumn>
        <TableColumn className="text-sm text-center">Email Sent</TableColumn>
        <TableColumn className="text-sm text-center">Status</TableColumn>
        <TableColumn className="text-sm text-center">Actions</TableColumn>
      </TableHeader>
      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell>
              <Skeleton className="h-3 w-3/5 rounded-lg mx-auto" />
            </TableCell>
            <TableCell>
              <Skeleton className="h-3 w-3/5 rounded-lg mx-auto" />
            </TableCell>
            <TableCell>
              <Skeleton className="h-3 w-3/5 rounded-lg mx-auto" />
            </TableCell>
            <TableCell>
              <Skeleton className="h-3 w-3/5 rounded-lg mx-auto" />
            </TableCell>
            <TableCell>
              <Skeleton className="h-3 w-1/5 rounded-lg mx-auto" />
            </TableCell>
          </TableRow>
        ) : (
          accounts.map((account, key) => {
            let Icon;
            switch (account.type) {
              case "GOOGLE":
                Icon = GMailIcon;
                break;
              case "OUTLOOK":
                Icon = OutlookIcon;
                break;
              default:
                Icon = SMTPIcon;
            }
            return (
              <TableRow key={key}>
                <TableCell className="text-center">
                  <span className="flex justify-center">
                    <Icon size={36} />
                  </span>
                </TableCell>
                <TableCell className="text-center">{account.email}</TableCell>
                <TableCell className="text-center">{account.counts}</TableCell>
                <TableCell className="text-center">
                  {account.verified ? (
                    <Button
                      color="success"
                      variant="bordered"
                      radius="full"
                      size="md"
                      className="hover:bg-success-300 hover:text-white"
                    >
                      Verified
                    </Button>
                  ) : (
                    <Button
                      color="danger"
                      radius="full"
                      size="md"
                      onClick={() => {
                        onVerify && onVerify(account);
                      }}
                    >
                      Verify
                    </Button>
                  )}
                </TableCell>
                <TableCell className="text-center">
                  <DeleteButton
                    content="Deleting this service will result in an automatic disconnection from your email account."
                    onDelete={() => onDelete && onDelete(account)}
                  >
                    <TrashIcon size={20} />
                  </DeleteButton>
                </TableCell>
              </TableRow>
            );
          })
        )}
      </TableBody>
    </Table>
  );
};

export default TableWrapper;
