import { useCallback, useEffect, useRef, useState } from "react";
import LoadControl from "./load-control";
import {
  LoadInfo,
  LoadInfo_Place,
  LoadNote,
  LoadNoteType,
  McReview,
} from "@/types";
import { useParams } from "react-router-dom";
import { getLoadInfo } from "@/server/loadinfo.service";
import ToastMessage from "@/components/toast";
import {
  addLoadNote,
  getLoadNotes,
  patchLoadNote,
} from "@/server/loadnote.service";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import SaveModal from "./load-control/save-modal";
import TryModal from "./load-control/try-modal";
import { useDisclosure } from "@nextui-org/react";
import ReviewsDialog from "@/components/reviews";
import { getReviews } from "@/server/mcReview.service";
import ShareModal from "./load-control/share-modal";
import AuthModal from "@/components/auth-modal";
import { getTollApi } from "@/server/api.service";

import TollIcon from "@/assets/img/toll.png";

const initialValue: LoadInfo = {
  company: "",
  email: "",
  id: "",
  mc: "",
  phone: "",
  places: [],
  source: "",
  routeInfo: { estimatedTimeMinutes: 0, tolls: "", tripMiles: 0 },
  load: {
    commodity: "",
    pickUpDate: "",
    rate: "",
    ref: "",
    truck: "",
    weight: "",
  },
  additionalData: {
    comments1: "",
    comments2: "",
    dock_hours: "",
    pickup_hours: "",
  },
  createdAt: "",
  updatedAt: "",
};

const initialRouteInfo = { estimatedTimeMinutes: 0, tolls: "", tripMiles: 0 };

const center = {
  lat: 37.839333,
  lng: -84.27002,
};

const LoadsPage = () => {
  const directionsService = useRef<any>(null);
  const directionsRenderer = useRef<any>(null);
  const map = useRef<any>(null);
  const params = useParams();

  const [loadInfo, setLoadInfo] = useState<LoadInfo>(initialValue);
  const [routeInfo, setRouteInfo] =
    useState<LoadInfo["routeInfo"]>(initialRouteInfo);
  const [loadNote, setLoadNote] = useState<LoadNote>({
    loadId: "",
    notes: [],
    status: "VISITED",
    tags: [],
    userId: -1,
  });

  const [mcReviews, setMcReviews] = useState<McReview[]>([]);

  const [loading, setLoading] = useState(false);
  const [noteSaving, setNoteSaving] = useState(false);
  const [loadSaving, setLoadSaving] = useState(false);

  const [openReview, setOpenReview] = useState(false);

  const {
    isOpen: isSaveOpen,
    onClose: onSaveClose,
    onOpen: onSaveOpen,
  } = useDisclosure();
  const {
    isOpen: isShareOpen,
    onClose: onShareClose,
    onOpen: onShareOpen,
  } = useDisclosure();
  const {
    isOpen: isAuthOpen,
    onClose: onAuthClose,
    onOpen: onAuthOpen,
  } = useDisclosure();

  const authState = useSelector((state: RootState) => state.authReducer.value);
  window.google = window.google || {};

  const initializeMap = () => {
    const mapState = {
      center,
      zoom: 5,
      options: {
        gestureHandling: "greedy",
        disableDefaultUI: true,
      },
    };
    if (map.current) return;
    const mapContainer = document.getElementById("map");
    if (!mapContainer) return;
    const mapi = new google.maps.Map(mapContainer, mapState);
    map.current = mapi;
    directionsService.current = new google.maps.DirectionsService();
    directionsRenderer.current = new google.maps.DirectionsRenderer();
  };

  const tollGet = async (
    polyline: string,
    duration: string,
    distance: string
  ): Promise<number> => {
    let toll = 0.0;
    try {
      const data = await getTollApi(polyline);
      if (data.costs.minimumTollCost) toll = data.costs.minimumTollCost;
      data.tolls.forEach((toll: any) => {
        new google.maps.Marker({
          position: { lat: toll.lat, lng: toll.lng },
          map: map.current,
          icon: {
            url: TollIcon,
            scaledSize: new google.maps.Size(30, 30),
          },
        });
      });
    } catch (e) {
      return 0.0;
    }

    return toll;
  };

  useEffect(() => {
    const func = async () => {
      if (!params.id) return;
      setLoading(true);

      try {
        const resp = await getLoadInfo(params.id);
        const noteResp = (
          await getLoadNotes({ loadId: params.id, userId: authState.userId })
        )[0];
        const reviewResp = await getReviews(resp.mc);

        setMcReviews(reviewResp);
        setLoadInfo(resp);

        let noteInitial: LoadNote = {
          loadId: params.id,
          notes: [],
          status: "VISITED",
          tags: [],
          userId: authState.userId,
        };
        if (!noteResp) {
          noteInitial = await addLoadNote(noteInitial);
          setLoadNote(noteInitial);
        } else setLoadNote(noteResp);
      } catch (error) {
        setLoadInfo(initialValue);
      }
      setLoading(false);
    };
    func();
  }, [params.id, authState.userId]);

  // Initialize map
  useEffect(() => {
    initializeMap();
    drawOnMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    drawOnMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadInfo.places]);

  const drawOnMap = async () => {
    const places: LoadInfo_Place[] = loadInfo.places;

    if (places.some((place) => place.address === "")) return;

    if (places.length === 0) return;
    if (!map.current) return;
    if (loading) return;
    if (places.length === 1 && places[places.length - 1].address === "") return;
    let request = {
      origin: places[0].address,
      destination: places[places.length - 1].address,
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
      waypoints: places.slice(1, -1).map((place) => {
        return { location: place.address, stopover: true };
      }),
    };

    directionsService.current.route(
      request,
      async (result: any, status: any) => {
        if (status === google.maps.DirectionsStatus.OK) {
          let distance = 0;
          result?.routes[0]?.legs.forEach((leg: any) => {
            distance += leg.distance.value;
          });
          distance = Number((distance / 1609.34).toFixed(2));

          let duration = 0;
          result?.routes[0]?.legs.forEach((leg: any) => {
            duration += leg.duration.value;
          });

          const toll = await tollGet(
            result?.routes[0].overview_polyline,
            duration.toString(),
            distance.toString()
          );

          setRouteInfo({
            ...routeInfo,
            estimatedTimeMinutes: duration,
            tripMiles: distance,
            tolls: toll.toFixed(2),
          });

          directionsRenderer.current.setMap(map.current);
          directionsRenderer.current.setDirections(result);
        }
      }
    );
  };

  const onSaveNote = async (note: string) => {
    if (!authState.isAuth) return onAuthOpen();
    const notes: LoadNoteType[] = [
      ...loadNote.notes,
      {
        email: authState.email,
        text: note,
        userId: authState.userId,
        createdAt: new Date(),
      },
    ];
    setNoteSaving(true);
    const resp = await patchLoadNote({ id: loadNote.id, notes });
    setNoteSaving(false);
    setLoadNote(resp);
    ToastMessage("success", "Successfully created a new note");
  };

  const onSaveLoad = async () => {
    if (!authState.isAuth) return onAuthOpen();
    try {
      setLoadSaving(true);
      const resp = await patchLoadNote({ id: loadNote.id, status: "SAVED" });
      setLoadNote(resp);
    } catch (error) {}
    setLoadSaving(false);
    onSaveOpen();
  };

  const handlePlaceChange = useCallback(
    (data: LoadInfo_Place[]) => {
      setLoadInfo({ ...loadInfo, places: data });
    },
    [loadInfo]
  );

  return (
    <section className="flex">
      <LoadControl
        reviews={mcReviews}
        loadInfo={loadInfo}
        routeInfo={routeInfo}
        loading={loading}
        loadNote={loadNote}
        loadSaving={loadSaving}
        onSaveLoad={onSaveLoad}
        onShareLoad={onShareOpen}
        onSaveNote={onSaveNote}
        noteSaving={noteSaving}
        //    onFocusPlace={handleFocus}
        onOpenReview={() => setOpenReview(true)}
        onPlaceChange={handlePlaceChange}
      />
      <div
        id="map"
        className="map-container"
        style={{
          top: 0,
          right: 0,
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
      />
      {openReview && (
        <div className="absolute z-20 rounded bg-white h-screen top-0 left-0 flex flex-col transition-all  w-[512px] overflow-auto">
          <ReviewsDialog
            company={loadInfo.company}
            mc={loadInfo.mc}
            onClose={() => setOpenReview(false)}
          />
        </div>
      )}
      <ShareModal
        isOpen={isShareOpen}
        onClose={onShareClose}
        loadInfo={loadInfo}
      />
      <SaveModal isOpen={isSaveOpen} onClose={onSaveClose} />
      <TryModal />
      <AuthModal isOpen={isAuthOpen} onClose={onAuthClose} />
    </section>
  );
};

export default LoadsPage;
