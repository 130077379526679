"use client";

import { Navbar, NavbarContent, NavbarItem } from "@nextui-org/navbar";
import UserDetail from "./user-detail";
import Login from "./login";

const NavBarWrapper = ({
  isMapPage,
  isAuth = true,
}: {
  isMapPage?: boolean;
  isAuth?: boolean;
}) => {
  if (isMapPage) {
    return (
      <div className="absolute right-4 top-4 z-40">
        {isAuth === true ? <UserDetail small /> : <Login small />}
      </div>
    );
  }

  return (
    <Navbar
      className="backdrop-filter-none h-20 bg-transparent"
      maxWidth="full"
    >
      <NavbarContent justify="end" className="bg-transparent">
        <NavbarItem>{isAuth === true ? <UserDetail /> : <Login />}</NavbarItem>
      </NavbarContent>
    </Navbar>
  );
};

export default NavBarWrapper;
