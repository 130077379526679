import ToastMessage from "@/components/toast";
import { createAccount } from "@/server/account.service";
import { Account, AccountCreateDto, AccountMessage } from "@/types";
import { Button } from "@nextui-org/button";
import { Input } from "@nextui-org/input";
import { ModalBody, ModalHeader } from "@nextui-org/modal";
import { useEffect, useState } from "react";
import { Switch } from "@nextui-org/react";

const initialData: AccountCreateDto = {
  email: "",
  smtpEncryptionMethod: "",
  smtpPassword: "",
  smtpServerHost: "",
  smtpServerPort: "",
  type: "SMTP",
};

const SMTPService = ({ account, onBack, onAddSMTP }: { account: Account | null; onBack: any; onAddSMTP: any }) => {
  const [errorMessage, setErrorMessage] = useState<AccountCreateDto>({
    email: "",
    smtpEncryptionMethod: "",
    smtpPassword: "",
    smtpServerHost: "",
    smtpServerPort: "",
    type: "",
  });

  const [data, setData] = useState<AccountCreateDto>(initialData);

  useEffect(() => {
    if (account) {
      setData({
        ...data,
        smtpServerHost: account.smtpServerHost,
        email: account.email,
      });
    } else {
      setData(initialData);
    }
    //eslint-disable-next-line
  }, [account]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: any) => {
    if (e.target.name === "smtpEncryptionMethod") {
      setData({ ...data, [e.target.name]: e.target.checked ? "STARTTLS" : "NONE" });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const onAddService = async (e: any) => {
    e.preventDefault();

    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    let message = errorMessage;

    if (!regex.test(data.email) || !data.email) message = { ...message, email: "Please enter a valid email " };

    if (!data.smtpPassword) message = { ...message, smtpPassword: "Please enter password " };

    if (!data.smtpServerHost) message = { ...message, smtpServerHost: "Please enter SMTP Server Host " };

    if (!data.smtpServerPort) message = { ...message, smtpServerPort: "Please enter SMTP Server Port " };

    setErrorMessage(message);

    if (Object.values(message).join("") !== "") {
      return;
    }

    setIsSubmitting(true);
    try {
      const resp: AccountMessage = await createAccount(data);
      if (resp.account) {
        onAddSMTP();
        if (account) ToastMessage("success", "Success verifying account");
        else ToastMessage("success", "Success creating an account");
      } else ToastMessage("error", resp.message);
    } catch (error) {
      ToastMessage("error", "Error while creating account");
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <ModalHeader>
        <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">Add new account</h3>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={onAddService}>
          <Input
            labelPlacement="outside"
            variant="bordered"
            className="relative mb-4"
            id="email"
            name="email"
            placeholder="you@example.com"
            label="Username"
            validationState={errorMessage["email"] !== "" ? "invalid" : "valid"}
            errorMessage={errorMessage["email"]}
            onClick={() => setErrorMessage({ ...errorMessage, email: "" })}
            value={data.email || ""}
            onChange={handleChange}
          />

          <Input
            labelPlacement="outside"
            variant="bordered"
            className="relative mb-4"
            id="smtpPassword"
            name="smtpPassword"
            placeholder="Password"
            label="Password"
            type="password"
            validationState={errorMessage["smtpPassword"] !== "" ? "invalid" : "valid"}
            errorMessage={errorMessage["smtpPassword"]}
            onClick={() => setErrorMessage({ ...errorMessage, smtpPassword: "" })}
            value={data.smtpPassword || ""}
            onChange={handleChange}
          />

          <Input
            labelPlacement="outside"
            variant="bordered"
            className="relative mb-4"
            id="smtpServerHost"
            name="smtpServerHost"
            placeholder="server host"
            label="Server Host"
            validationState={errorMessage["smtpServerHost"] !== "" ? "invalid" : "valid"}
            errorMessage={errorMessage["smtpServerHost"]}
            onClick={() => setErrorMessage({ ...errorMessage, smtpServerHost: "" })}
            value={data.smtpServerHost || ""}
            onChange={handleChange}
          />

          <Input
            labelPlacement="outside"
            variant="bordered"
            className="relative mb-4"
            id="smtpServerPort"
            name="smtpServerPort"
            placeholder="server port"
            label="Port"
            validationState={errorMessage["smtpServerPort"] !== "" ? "invalid" : "valid"}
            errorMessage={errorMessage["smtpServerPort"]}
            onClick={() => setErrorMessage({ ...errorMessage, smtpServerPort: "" })}
            value={data.smtpServerPort || ""}
            onChange={handleChange}
          />

          <Switch className="mt-2 mb-3" name="smtpEncryptionMethod" onChange={handleChange}>
            Use SSL
          </Switch>

          <div className="flex justify-end gap-2">
            <Button color="primary" variant="light" onClick={onBack} className="data-[pressed=true]:scale-100">
              Go back
            </Button>
            <Button type="submit" color="primary" className="data-[pressed=true]:scale-100" isLoading={isSubmitting}>
              Add new service
            </Button>
          </div>
        </form>
      </ModalBody>
    </>
  );
};

export default SMTPService;
