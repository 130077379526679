import { search, sessionToken } from "@/config/mapbox.config";
import { LoadInfo_Place } from "@/types";
import { SearchBoxSuggestion } from "@mapbox/search-js-core";

const AutocompleteItem = ({ value, onClick }: { onClick?: any, value: SearchBoxSuggestion, key: number }) => {
    const handleClick = async () => {
        const data: LoadInfo_Place = {
            address: "",
            coordinates: { lat: 0, long: 0 }
        }

        const { features } = await search.retrieve(value, { sessionToken });

        data.address = value.name + ", " + value.place_formatted
        data.coordinates = {
            lat: features[0].geometry.coordinates[1],
            long: features[0].geometry.coordinates[0]
        }

        onClick(data)
    }
    return (
        <li
            onClick={handleClick}
            className="p-1 rounded hover:bg-zinc-300 transition cursor-pointer select-none border-b last:border-none border-gray-200"
        >
            <p className="m-0">{value.name + ", " + value.place_formatted}</p>
            {/* <p className="m-0 text-gray-500 text-xs">{value.full_address}</p> */}
        </li>
    )
}

export default AutocompleteItem