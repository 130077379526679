export const mergetags_list = [{
    value: 'avail',
    title: 'Pickup Date'
}, {
    value: 'truck',
    title: 'truck'
}, {
    value: 'fp',
    title: 'Fp'
}, {
    value: 'origin',
    title: 'Origin'
}, {
    value: 'dest',
    title: 'Destination'
}, {
    value: 'trip',
    title: 'Trip'
}, {
    value: 'email',
    title: 'Email'
}, {
    value: 'do',
    title: 'deadhead'
}, {
    value: 'company',
    title: 'Company'
}, {
    value: 'rate',
    title: 'Rate'
}, {
    value: 'ref',
    title: 'Reference Number'
}]

export const toolbar = 'undo redo | preview |  image link | code | ' +
    'fontsize | ' +
    'bold italic forecolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | help'

export const plugins = [
    'image', 'preview', 'editimage', 'mergetags',
    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'quickbars',
    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
    'insertdatetime', 'table', 'code', 'help', 'wordcount', 'inlinecss'
]