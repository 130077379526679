import { AnyType, McReview } from "@/types"
import { Progress } from "@nextui-org/react"
import { useMemo } from "react"

const Summary = ({ reviews }: { reviews: McReview[] }) => {

    const counts = useMemo(() => {
        const result: AnyType = { '5': 0, '4': 0, '3': 0, '2': 0, '1': 0 }
        reviews.forEach(item => {
            result[item.rating.toFixed(0) as keyof AnyType]++
        })
        return result
    }, [reviews])

    const totalReviews = useMemo(() => reviews.length, [reviews])

    return (
        <>
            {
                Object.keys(counts)
                    .sort((a, b) => a < b ? 1 : -1)
                    .map(key => (
                        <div className='flex gap-2 items-center text-base font-medium' key={key}>
                            <p className='flex gap-1 items-center'>
                                <span className="w-3">{key}</span>
                                <span className='text-[#f1c163] text-lg'> &#9733; </span>
                            </p>
                            <Progress
                                aria-label="summary"
                                size='md'
                                value={(counts[key] / totalReviews * 100)}
                                classNames={{ track: "p-0.5" }}
                            />
                            <span>{counts[key]}</span>
                        </div>
                    ))
            }
        </>
    )
}

export default Summary