export const defaultValue = {
    subject: 'Load from {{origin}} to {{dest}}',
    body: '<p>Hello, team!</p><p><br></p><p>Can we get more info pls about the load by this ref</p>'
        + '<p>number&nbsp;{{ref}}&nbsp;and what is the best rate?</p><p><br></p><p>MC: 00000</p>'
        + '<p>Dispatch Team at&nbsp;</p><p>Alex Daniel</p><p>Number: +1234567890123</p>',
    variables: [
        {
            value: 'avail',
            title: 'Pickup Date'
        },
        {
            value: 'truck',
            title: 'truck'
        },

        {
            value: 'fp',
            title: 'Fp'
        },

        {
            value: 'origin',
            title: 'Origin'
        },

        {
            value: 'dest',
            title: 'Destination'
        },

        {
            value: 'trip',
            title: 'Trip'
        },

        {
            value: 'email',
            title: 'Email'
        },

        {
            value: 'do',
            title: 'deadhead'
        },

        {
            value: 'company',
            title: 'Company'
        },

        {
            value: 'rate',
            title: 'Rate'
        },

        {
            value: 'ref',
            title: 'Reference Number'
        },
    ],
};