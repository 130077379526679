export const AMPLITUDE_EVENTS = {
  /* General */
  dashboardAccountLoggedIn: "dashboardAccountLoggedIn",
  dashboardAccountLoggedOut: "dashboardAccountLoggedOut",
  /* Tabs */
  dashboardMyEmailsTabClicked: "dashboardMyEmailsTabClicked",
  dashboardMyTemplatesTabClicked: "dashboardMyTemplatesTabClicked",
  dashboardMyLoadsTabClicked: "dashboardMyLoadsTabClicked",
  dashboardBillingTabClicked: "dashboardBillingTabClicked",
  /* Tabs */
  dashboardNewEmailButtonClicked: "dashboardNewEmailButtonClicked",
  dashboardNewEmailAdded: "dashboardNewEmailAdded",
  dashboardDefaultEmailValueChanged: "dashboardDefaultEmailValueChanged",
  dashboardNewTemplateButtonClicked: "dashboardNewTemplateButtonClicked",
  dashboardNewTemplateCreated: "dashboardNewTemplateCreated",
  /* Map */
  loadConnectMapShareButtonClicked: "loadConnectMapShareButtonClicked",
  loadConnectMapEmailClicked: "loadConnectMapEmailClicked",
  loadConnectMapPhoneNumberClicked: "loadConnectMapPhoneNumberClicked",
  loadConnectMapSaveLoadButtonClicked: "loadConnectMapSaveLoadButtonClicked",
  loadConnectMapDnDUsed: "loadConnectMapDnDUsed",
  loadConnectMapPointChanged: "loadConnectMapPointChanged",
  loadConnectMapPointDeleted: "loadConnectMapPointDeleted",
  loadConnectMapPointAdded: "loadConnectMapPointAdded",
  loadConnectMapRateValueChanged: "loadConnectMapRateValueChanged",
  loadConnectMapMilesValueChanged: "loadConnectMapMilesValueChanged",
  loadConnectMapRpmValueChanged: "loadConnectMapRpmValueChanged",
  loadConnectMapNotesValueChanged: "loadConnectMapNotesValueChanged",
  loadConnectMapAddNotesButtonClicked: "loadConnectMapAddNotesButtonClicked",
  /* Onboarding */
  onboardingStarted: "onboardingStarted",
  onboardingBusinessTypeChanged: "onboardingBusinessTypeChanged",
  onboardingTrucksCountChanged: "onboardingTrucksCountChanged",
  onboardingLoadboardListChanged: "onboardingLoadboardListChanged",
  onboardingEditTemplateButtonClicked: "onboardingEditTemplateButtonClicked",
  onboardingEditTemplateSubjectChanged: "onboardingEditTemplateSubjectChanged",
  onboardingEditTemplateBodyChanged: "onboardingEditTemplateBodyChanged",
  onboardingEditTemplateVariableClicked:
    "onboardingEditTemplateVariableClicked",
  onboardingEditTemplateCancelButtonClicked:
    "onboardingEditTemplateCancelButtonClicked",
  onboardingEditTemplateSaveButtonClicked:
    "onboardingEditTemplateSaveButtonClicked",
  onboardingCreateTemplateButtonClicked:
    "onboardingCreateTemplateButtonClicked",
  onboardingCreateTemplateNameChanged: "onboardingCreateTemplateNameChanged",
  onboardingCreateTemplateSubjectChanged:
    "onboardingCreateTemplateSubjectChanged",
  onboardingCreateTemplateBodyChanged: "onboardingCreateTemplateBodyChanged",
  onboardingCreateTemplateVariableClicked:
    "onboardingCreateTemplateVariableClicked",
  onboardingCreateTemplateCancelButtonClicked:
    "onboardingCreateTemplateCancelButtonClicked",
  onboardingCreateTemplateSaveButtonClicked:
    "onboardingCreateTemplateSaveButtonClicked",
  onboardingDismissButtonClicked: "onboardingDismissButtonClicked",
  onboardingNextButtonClicked: "onboardingNextButtonClicked",
  onboardingBackButtonClicked: "onboardingBackButtonClicked",
  onboardingFinished: "onboardingFinished",
  /* Welcome page */
  welcomePageAddMoreButtonClicked: "welcomePageAddMoreButtonClicked",
  welcomePageLoadboardClicked: "welcomePageLoadboardClicked",
  welcomePageLoadboardsListChanged: "welcomePageLoadboardsListChanged",
  welcomePageUpdateLoadboardsModalSaveButtonClicked:
    "welcomePageUpdaleLoadboardsModalSaveButtonClicked",
  welcomePageUpdateLoadboardsModalCancelButtonClicked:
    "welcomePageUpdaleLoadboardsModalCancelButtonClicked",
  welcomePageTextTutorialClicked: "welcomePageTextTutorialClicked",
  welcomePageVideoClicked: "welcomePageVideoClicked",
  welcomePageTutorialModalContactUsButtonClicked:
    "welcomePageTutorialModalContactUsButtonClicked",
  welcomePageTutorialModalEmailClicked: "welcomePageTutorialModalEmailClicked",
  welcomePageTutorialModalPhoneClicked: "welcomePageTutorialModalPhoneClicked",
  welcomePageTutorialModalClosed: "welcomePageTutorialModalClosed",
};
