import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import { mergetags_list, toolbar, plugins } from "./config";

const EditorWrapper = ({ onChange, value, onLoad }: { value?: string; onLoad?: any; onChange?: Function }) => {
  return (
    <Editor
      value={value}
      onEditorChange={(val) => onChange && onChange(val)}
      onLoadContent={onLoad}
      apiKey="7w02pcin7frlhj2r9whi9i2qgqjk9cxv4vr01gmfviciyn30"
      init={{
        height: 450,
        menubar: false,
        image_title: true,
        paste_preprocess: (editor, args) => {
          if (args.content.includes("img")) {
            args.content = "";
          }
        },
        images_upload_handler: async (blobInfo: any, progress: any) => {
          const formData = new FormData();
          formData.append("files", blobInfo.blob(), blobInfo.filename());
          progress(50);
          const resp = await axios.post("https://strapi.carrierify.com/api/upload", formData, {
            headers: {
              Authorization: `Bearer 3dabb30ffad3c75d8df5e1c0a4a01513b3b261cfdb2c1915ce89ffd63b0d2f5756024b62a72e3ea8057a448d6b8017e89e1e5314db6aa680bc42027b6279deda773bf34d9443b83841d50963f4521265102c61d1e5828d053bf16f032d3d8945b166c3ea7820e0a3735f7d6f10f8c2381aaa80d5b03589ee45e5ad4b992912f2`,
            },
          });
          progress(100);
          return resp.data[0].url;
        },
        menu: { insert: { title: "Insert", items: "image link insertfile" } },
        plugins,
        mergetags_prefix: "{{",
        mergetags_list,
        quickbars_image_toolbar: "alignleft aligncenter alignright",
        toolbar,
        content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; line-height: 1; }",
      }}
    />
  );
};

export default EditorWrapper;
