import { FC } from "react";
import { LOAD_BOARDS, OTHER_LOAD_BOARD } from "@/constants/loadboards";
import { Checkbox, CheckboxGroup, CheckboxGroupProps } from "@nextui-org/react";

import { ReactComponent as SelectedIcon } from "@/assets/icons/selected-icon.svg";

export const LoadBoardsSelector: FC<CheckboxGroupProps> = (props) => {
  return (
    <CheckboxGroup
      {...props}
      classNames={{
        wrapper: "grid grid-cols-2 gap-2",
        label: "text-sm",
      }}
    >
      {LOAD_BOARDS.map((board) => (
        <LoadBoardRadio key={board.id} data={board} />
      ))}
      <LoadBoardRadio data={OTHER_LOAD_BOARD} />
    </CheckboxGroup>
  );
};

const LoadBoardRadio: FC<{ data: Record<string, string> }> = ({ data }) => {
  return (
    <Checkbox
      value={data.id}
      classNames={{
        base: "max-w-full w-full h-28 m-0 border rounded-lg relative data-[selected=true]:border-brand-700 data-[selected=true]:bg-brand-700/10 [&_svg]:data-[selected=true]:block hover:bg-brand-700/10",
        label:
          "w-full h-full text-sm text-gray-900 static flex flex-col items-center justify-center gap-2",
        wrapper: "hidden",
      }}
    >
      {data?.icon && (
        <img
          className="object-contain w-32 h-16"
          src={data?.icon}
          alt={data.name}
        />
      )}
      <h4 className="text-sm font-normal">{data?.name}</h4>
      <SelectedIcon className="absolute top-2 right-2 hidden text-brand-700" />
    </Checkbox>
  );
};
