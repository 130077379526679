import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, InitialState } from "@/types/index";
import { PERSIST_STORE_NAME } from "@/config/app.config";

const initialState = {
  value: {
    isAuth: false,
    email: "",
    userId: -1,
    accessToken: "",
    refreshToken: "",
    extensionToken: "",
    features: [],
    limitTempl: 0,
    limitEmails: 0,
    emails: 10,
    createdAt: "",
    trialDays: 1,
    accountVerified: true,
  } as AuthState,
} as InitialState;

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.value.accessToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.value.refreshToken = action.payload;
    },
    setAuthData: (state, action: PayloadAction<AuthState>) => {
      state.value = action.payload;
    },
    logOut: (state) => {
      state.value = initialState.value;
      localStorage.removeItem(PERSIST_STORE_NAME);
    },
    logIn: (state, action: PayloadAction<AuthState>) => {
      return {
        value: action.payload,
      };
    },
  },
});

export const { logOut, logIn, setAuthData, setAccessToken, setRefreshToken } =
  auth.actions;

export default auth.reducer;
