import { FC } from "react";
import { Button, Card, CardBody } from "@nextui-org/react";

import { ReactComponent as SparkleIcon } from "@/assets/icons/sparkle-icon.svg";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { NavLink } from "react-router-dom";

interface Props {
  isOpen: boolean;
}

export const SidebarTrialStats: FC<Props> = ({ isOpen }) => {
  const user = useSelector((state: RootState) => state.authReducer.value);

  const { features, trialDays, createdAt } = user;

  const daysBeetween = Math.floor(
    (new Date().getTime() - new Date(createdAt).getTime()) / (1000 * 3600 * 24)
  );

  const leftTrialDays = trialDays - daysBeetween;
  const isTrialExpired = leftTrialDays < 1;
  const isUnlimitedEmails = features.includes("email");
  const dayLiteral = trialDays === 1 ? "day" : "days";

  const mainLiteral = isTrialExpired
    ? `Your Pro Trial is Expired`
    : `Your Pro Trial is about to expire in ${leftTrialDays} ${dayLiteral}`;

  const shortLiteral = isTrialExpired
    ? `Expired`
    : `Pro Trial Expire in ${leftTrialDays} ${dayLiteral}`;

  const UpgradeButton = (
    <Button
      as={NavLink}
      startContent={<SparkleIcon />}
      fullWidth
      variant="solid"
      color="primary"
      radius="lg"
      isIconOnly={!isOpen}
      to="/pricing"
    >
      {isOpen && "Upgrade now"}
    </Button>
  );

  if (isUnlimitedEmails) return null;

  if (!isOpen)
    return (
      <div className="flex flex-col gap-2">
        <h4 className="text-xs text-brand-700 font-normal">{shortLiteral}</h4>
        {UpgradeButton}
      </div>
    );

  return (
    <Card className="w-[188px] shadow-none bg-brand-300/20 p-3 rounded-xl">
      <CardBody className="flex flex-col gap-3 p-0">
        {isOpen && (
          <h4 className="text-sm text-brand-700 font-normal">{mainLiteral}</h4>
        )}
        {UpgradeButton}
      </CardBody>
    </Card>
  );
};
