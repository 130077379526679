import ToastMessage from "@/components/toast";
import {
  logOut,
  setAccessToken,
  setRefreshToken,
  setAuthData,
} from "@/redux/slices/auth-slice";
import { setUserInfo } from "@/redux/slices/user-info-slice";
import { RootState } from "@/redux/store";
import {
  sendAuthEmail,
  userMe,
  userUnfo,
  verifyTokenLink,
} from "@/server/auth.service";
import { Progress } from "@nextui-org/progress";
import { Button } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, Navigate, redirect, Link } from "react-router-dom";

const VerifyingPage = () => {
  const dispatch = useDispatch();
  const [query] = useSearchParams();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [invalid, setInvalid] = useState<boolean>(false);
  const authState = useSelector((state: RootState) => state.authReducer.value);

  useEffect(() => {
    const authHandler = async () => {
      let accessToken = query.get("accessToken") || "";
      let refreshToken = query.get("refreshToken") || "";
      let token = query.get("token") || "";
      let email = query.get("email") || "";

      if (authState.isAuth && accessToken === "" && token === "") return;

      if (email && token) {
        try {
          const userData = await verifyTokenLink({ email, token });
          accessToken = userData.accessToken;
          refreshToken = userData.refreshToken;
        } catch (error) {
          setInvalid(true);
        }
      }

      dispatch(setAccessToken(accessToken));
      dispatch(setRefreshToken(refreshToken));

      try {
        const data = await userMe();
        const userData = await userUnfo();

        dispatch(
          setAuthData({
            isAuth: true,
            accessToken: accessToken,
            refreshToken: refreshToken,
            email: data.email,
            extensionToken: data.extensionToken,
            features: data.features,
            limitEmails: data.limitEmails,
            limitTempl: data.limitTempl,
            userId: data.userId,
            emails: data.emails,
            createdAt: data.createdAt,
            trialDays: data.trialDays,
            accountVerified: data.accountVerified,
          })
        );
        dispatch(setUserInfo(userData));
        redirect("/");
      } catch (error) {
        setInvalid(true);
        dispatch(logOut());
      }
    };
    authHandler();
    // eslint-disable-next-line
  }, []);

  const resendCode = async () => {
    const email = query.get("email") || "";
    if (email) {
      setSubmitting(true);
      const isSent = await sendAuthEmail(email);
      setSubmitting(!isSent);
      ToastMessage("success", `Check your mail ${email}`);
    }
  };

  if (authState.isAuth) return <Navigate to={query.get("redirect") || `/`} />;

  if (invalid)
    return (
      <div className="h-full w-full flex flex-col justify-between  gap-5">
        <div className="w-full flex flex-col items-center gap-11">
          <h3 className="text-2xl font-semibold text-gray-950 text-center">
            Check your inbox and verify
          </h3>
          <div className="flex flex-col gap-3">
            <p className="text-lg leading-7">
              We’ve sent a verification link to your email address{" "}
              <span className="text-brand-700 font-bold">
                {query.get("email")}
              </span>{" "}
            </p>
            <p className="text-lg leading-7">
              Complete your sign up and activate your account.
            </p>
          </div>
        </div>
        <p className="text-lg leading-7 mt-20">
          Can’t find email? Check your spam
        </p>
        <div className="w-full flex flex-col gap-3">
          <Button
            type="submit"
            variant="solid"
            radius="lg"
            color="primary"
            onClick={resendCode}
            isLoading={submitting}
          >
            Resend link
          </Button>
          <Button
            as={Link}
            to="https://loadconnect.io/contact/"
            size="md"
            radius="lg"
            variant="bordered"
            fullWidth
            target="_blank"
          >
            Contact support
          </Button>
        </div>
      </div>
    );

  return (
    <Progress
      size="sm"
      isIndeterminate
      aria-label="Loading..."
      className="max-w-md"
    />
  );
};

export default VerifyingPage;
