import { TypeOptions, toast } from 'react-toastify'
import ToastContent from './ToastContent'


const ToastMessage = (type: TypeOptions | undefined, message: string | undefined) => {
    toast(
        ToastContent(message),
        {
            type: type
        }
    )
}

export default ToastMessage
