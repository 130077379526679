"use client";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/button";
import { Input } from "@nextui-org/input";
import { sendAuthEmail } from "@/server/auth.service";
import { API_CARRIERIFY_PREFIX } from "@/config/app.config";
import { Divider, Link } from "@nextui-org/react";

import { ReactComponent as GoogleLogo } from "@/assets/svg/google-logo.svg";
import { ReactComponent as OutlookLogo } from "@/assets/svg/outlook-logo.svg";

const SignInPage = () => {
  const navigate = useNavigate();
  const [isNewUser, setIsNewUser] = useState<boolean>(true);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const googleRedirect = () => {
    window.location.href = `${API_CARRIERIFY_PREFIX}/auth/dash-auth`;
  };

  const outlookRedirect = () => {
    window.location.href = `${API_CARRIERIFY_PREFIX}/auth/outlook`;
  };

  const onSignIn = async (event: any) => {
    event.preventDefault();
    const email = event.target.email.value;
    if (email === "") {
      setErrorMessage("Please enter a valid email");
      return;
    }
    setSubmitting(true);
    const isSent = await sendAuthEmail(email);
    setSubmitting(!isSent);
    navigate("/sign-in/verifying?email=" + email);
  };

  return (
    <div className="w-full flex flex-col items-center gap-11">
      <h3 className="w-[90%] text-2xl font-semibold text-gray-950 text-center">
        {isNewUser ? "Start your 7 day Pro trial for Free" : "Welcome back!"}
      </h3>
      <div className="flex flex-col items-center w-full gap-6">
        <div className="flex flex-col gap-2 w-full">
          <Button
            size="md"
            radius="full"
            variant="bordered"
            startContent={<GoogleLogo className="shrink-0" />}
            onClick={googleRedirect}
            fullWidth
            className="text-sm text-gray-700 p-3 font-roboto"
          >
            Sign {isNewUser ? "up" : "in"} with Google
          </Button>
          <Button
            size="md"
            radius="full"
            variant="bordered"
            startContent={<OutlookLogo className="shrink-0" />}
            onClick={outlookRedirect}
            fullWidth
            className="text-sm text-gray-700 p-3 font-roboto"
          >
            Sign {isNewUser ? "up" : "in"} with Outlook
          </Button>
        </div>

        <div className="w-full flex items-center justify-center gap-2">
          <Divider className="shrink" />
          OR
          <Divider className="shrink" />
        </div>
        <div className="w-full flex flex-col gap-5 items-center">
          <form onSubmit={onSignIn} className="flex flex-col gap-8 w-full">
            <Input
              id="email"
              type="email"
              labelPlacement="outside"
              variant="bordered"
              radius="lg"
              placeholder="you@example.com"
              label="Email address"
              validationState={errorMessage !== "" ? "invalid" : "valid"}
              errorMessage={errorMessage}
              disabled={isSubmitting}
              onClick={() => setErrorMessage("")}
            />
            <Button
              type="submit"
              variant="solid"
              radius="lg"
              color="primary"
              isLoading={isSubmitting}
            >
              Continue
            </Button>
          </form>
          <div className="flex items-center gap-2">
            <p className="text-gray-800">
              {isNewUser ? "Already" : "Don't"} have an account?
            </p>
            <Link
              size="sm"
              className="p-0 text-brand-900 cursor-pointer"
              onClick={() => setIsNewUser((prev) => !prev)}
            >
              Sign {isNewUser ? "in" : "up"}
            </Link>
          </div>
        </div>
      </div>
      {isNewUser && (
        <div className="text-center text-gray-500">
          By signing up, you agree to our{" "}
          <Link
            underline="always"
            size="sm"
            className="p-0 text-brand-900"
            href="https://loadconnect.io/terms-of-condition/"
            target="_blank"
          >
            Terms of Services
          </Link>{" "}
          &{" "}
          <Link
            underline="always"
            size="sm"
            className="p-0 text-brand-900"
            href="https://loadconnect.io/privacy-policy/"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </div>
      )}
    </div>
  );
};

export default SignInPage;
