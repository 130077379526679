import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInfo } from "@/types/index";

const initialState = {
  loadboards: ["dat-one"],
  trucksAmount: "1",
  userType: "carrier",
} as UserInfo;

export const userInfo = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<UserInfo>) => {
      const { userType, trucksAmount, loadboards } = action.payload;
      return {
        userType: userType || initialState.userType,
        trucksAmount: trucksAmount || initialState.trucksAmount,
        loadboards: loadboards.length ? loadboards : initialState.loadboards,
        source: action.payload.source,
      };
    },
  },
});

export const { setUserInfo } = userInfo.actions;

export default userInfo.reducer;
