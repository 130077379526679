import { TUTORIALS } from "@/constants/tutorials";
import { Tutorial } from "./Tutorial";

export const Tutorials = () => {
  return (
    <div className="w-full h-full flex flex-col gap-5 p-8">
      <h2 className="text-xl font-medium">Tutorials & Guides</h2>
      <div className="h-full w-full max-w-[1160px] max-h-[360px] grid grid-cols-4 grid-rows-2 gap-2">
        {TUTORIALS.map((tutorial) => (
          <Tutorial key={tutorial.id} data={tutorial} />
        ))}
      </div>
    </div>
  );
};
