import { CheckIcon, LinkIcon } from "@/components/icons";
import { LoadInfo } from "@/types";
import { Button, Input, Modal, ModalBody, ModalContent, ModalHeader, Textarea } from "@nextui-org/react";
import { useEffect, useState } from "react";

const formatOutput = (data: any) => {
  const output = [];
  try {
    output.push(`MC: ${data.mc}`);
    output.push(`Company: ${data.company}`);

    output.push("Load:");
    if (data.places.length > 0) output.push(`  Origin: ${data.places[0]?.address}`);

    if (data.places.length > 1)
      for (var i = 1; i < data.places.length - 1; i++) {
        output.push(`  Waypoint: ${data.places[i]?.address}`);
      }

    if (data.places.length > 1) output.push(`  Destination: ${data.places[data.places.length - 1]?.address}`);

    output.push("Rate:");
    output.push(`  Total: ${data.load.rate}`);

    output.push("LoadInfo:");
    for (const key in data.additionalData) {
      output.push(`  ${key}: ${data.additionalData[key]}`);
    }

    output.push("Contact:");
    output.push(`  email: ${data.email.trim()}`);
    output.push(`  phone: ${data.phone}`);
  } catch (e) {}

  return output.join("\n");
};

const ShareModal = ({ isOpen, onClose, loadInfo }: { isOpen?: boolean; onClose?: any; loadInfo?: LoadInfo }) => {
  const [copyLink, setCopyLink] = useState(false);
  const [copyLoad, setCopyLoad] = useState(false);
  const [text, setText] = useState("");
  useEffect(
    function () {
      setText(formatOutput(loadInfo));
    },
    [loadInfo]
  );

  const handleCopy = async () => {
    await navigator.clipboard.writeText(window.location.href);
    setCopyLink(true);
  };

  const handleCopyLoad = async () => {
    await navigator.clipboard.writeText(formatOutput(loadInfo));
    setCopyLoad(true);
  };

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} classNames={{ closeButton: "bg-gray-100 text-primary" }}>
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody className="-mt-5">
          <div className="flex gap-8 items-center">
            <div className="text-primary">
              <LinkIcon size={32} />
            </div>
            <div className="flex flex-col gap-2">
              <h3>Share this load</h3>
              <p>If you like this article share it with your friends.</p>
            </div>
          </div>
          <div>
            <Input
              variant="bordered"
              value={window.location.href}
              placeholder="https://example.com/article"
              endContent={
                !copyLink ? (
                  <Button color="primary" className="h-7 min-w-[120px]" onClick={handleCopy}>
                    Copy Link
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    className="h-7 min-w-[120px]"
                    onClick={handleCopy}
                    startContent={
                      <span className="text-white">
                        <CheckIcon size={16} />
                      </span>
                    }
                  >
                    Link Copied
                  </Button>
                )
              }
            />
          </div>
          <div className="relative overflow-hidden">
            <div
              onClick={handleCopyLoad}
              className="bg-primary absolute top-[-9px] right-[-27px] z-10 text-white h-11 w-20 flex justify-center items-end rotate-45 cursor-pointer"
            >
              {copyLoad ? "Copied" : "Copy"}
            </div>
            <Textarea
              id="text-loadinfo"
              value={text}
              minRows={20}
              maxRows={20}
              variant="bordered"
              label=" "
              labelPlacement="inside"
              onValueChange={setText}
            ></Textarea>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
