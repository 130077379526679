import {
  Radio,
  RadioGroup,
  RadioProps,
  Select,
  SelectItem,
  SelectProps,
} from "@nextui-org/react";
import { ChangeEvent, FC, useContext } from "react";

import { LoadBoardsSelector } from "@/components/loadboards-selector";
import { StepContext } from "..";
import { AMPLITUDE_EVENTS } from "@/analytics/events";
import { sendAmplitudeData } from "@/analytics";

export const PersonalInformation = () => {
  const {
    userType,
    setUserType,
    trucksAmount,
    setTrucksAmount,
    loadboards,
    setLoadboards,
  } = useContext(StepContext);

  const handleUserTypeChange = (value: string) => {
    setUserType(value);
    sendAmplitudeData(AMPLITUDE_EVENTS.onboardingBusinessTypeChanged);
  };

  const handleTrucksAmountChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setTrucksAmount(e.target.value);
    sendAmplitudeData(AMPLITUDE_EVENTS.onboardingTrucksCountChanged);
  };

  const handleLoadboardsChange = (value: string[]) => {
    setLoadboards(value);
    sendAmplitudeData(AMPLITUDE_EVENTS.onboardingLoadboardListChanged);
  };

  return (
    <div className="w-full flex flex-col gap-5">
      <h3 className="text-base font-normal">Let's set up your account</h3>
      <RadioGroup
        classNames={{
          wrapper: "flex-nowrap",
        }}
        label="What's your business type?"
        orientation="horizontal"
        value={userType}
        onValueChange={handleUserTypeChange}
      >
        <CustomRadio value="carrier">Carrier</CustomRadio>
        <CustomRadio value="carrier-broker">Carrier & Broker</CustomRadio>
      </RadioGroup>

      <CustomSelect
        label="How many trucks do you operate?"
        onChange={handleTrucksAmountChange}
        selectedKeys={[trucksAmount]}
      />

      <LoadBoardsSelector
        label="Which load boards do you use?"
        onChange={handleLoadboardsChange}
        value={loadboards}
      />
    </div>
  );
};

export const CustomRadio: FC<RadioProps> = (props) => {
  const { children, ...otherProps } = props;

  return (
    <Radio
      {...otherProps}
      classNames={{
        base: "w-full max-w-full flex items-center justify-center p-3 rounded-xl border border-gray-200 m-0 data-[selected=true]:border-primary data-[selected=true]:bg-brand-700/10 hover:bg-brand-700/10",
        wrapper: "hidden",
        labelWrapper: "m-0",
        label: "text-sm text-gray-900 data-[selecled=true]:text-brand-700",
      }}
    >
      {children}
    </Radio>
  );
};

export const CustomSelect: FC<Omit<SelectProps, "children">> = (props) => {
  return (
    <Select
      {...props}
      labelPlacement="outside"
      classNames={{
        label: "!text-gray-500 opacity-60",
        trigger:
          "border border-gray-200 bg-transparent shadow-none rounded-lg hover:!bg-brand-700/10",
      }}
    >
      {OPTIONS.map((option) => (
        <SelectItem
          classNames={{
            base: "hover:!bg-brand-700/10 data-[selected=true]:!bg-brand-700/10",
          }}
          key={option.key}
        >
          {option.label}
        </SelectItem>
      ))}
    </Select>
  );
};

const OPTIONS = [
  { label: "1-10", key: "1" },
  { label: "10-30", key: "10" },
  { label: "30-50", key: "30" },
  { label: "50-100", key: "50" },
  { label: "+100", key: "100" },
];
