import { RootState } from "@/redux/store";
import { getLoadTable } from "@/server/loadinfo.service";
import { getLoadNotes } from "@/server/loadnote.service";
import { LoadInfo } from "@/types";
import {
  Button,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  cn,
  Skeleton,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const LoadTable = () => {
  const [take] = useState(7);
  const [skip, setSkip] = useState(0);

  const [loads, setLoads] = useState<LoadInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const [savedMode, setSavedMode] = useState(true);
  const { userId } = useSelector((state: RootState) => state.authReducer.value);

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      setLoads([]);
      const savedNotes = await getLoadNotes({
        status: savedMode ? "SAVED" : "VISITED",
        userId,
        skip,
        take,
      });

      if (savedNotes.length === 0) setLoads([]);
      else {
        const savedLoads = await getLoadTable(
          savedNotes.map((item) => item.loadId)
        );
        setLoads(savedLoads);
      }
      setLoading(false);
    };

    func();
  }, [userId, savedMode, skip, take]);

  return (
    <div className="flex-grow px-8 py-6">
      <h2>My Loads</h2>
      <Helmet>
        <title>LoadConnect's Load Management Dashboard</title>
      </Helmet>
      <div className="flex gap-2 my-4">
        <Button
          onClick={() => setSavedMode(true)}
          variant={savedMode ? "flat" : "bordered"}
          className={cn(
            "border-[#EFF0FE] data-[pressed=true]:scale-100 hover:bg-gray-200",
            savedMode && "bg-[#EFF0FE]"
          )}
        >
          Saved Loads
        </Button>
        <Button
          onClick={() => setSavedMode(false)}
          variant={savedMode ? "bordered" : "flat"}
          className={cn(
            "border-[#EFF0FE] data-[pressed=true]:scale-100 hover:bg-gray-50",
            !savedMode && "bg-[#EFF0FE] "
          )}
        >
          Opened Loads
        </Button>
      </div>

      <Table
        shadow="none"
        classNames={{
          wrapper: "p-0",
          table: "min-h-[150px]",
          tr: "cursor-pointer hover:bg-gray-100",
          td: "p-4 text-black",
          th: "p-4",
        }}
        aria-label="Load Table"
      >
        <TableHeader>
          <TableColumn className="text-sm text-center">No</TableColumn>
          <TableColumn className="text-sm text-center">Posted Date</TableColumn>
          <TableColumn className="text-sm text-left">Origin</TableColumn>
          <TableColumn className="text-sm text-left">Destination</TableColumn>
          <TableColumn className="text-sm text-center">Stops</TableColumn>
          <TableColumn className="text-sm text-center">Company</TableColumn>
          <TableColumn className="text-sm text-center">Weight</TableColumn>
          <TableColumn className="text-sm text-center">Rate/RPM</TableColumn>
          <TableColumn className="text-sm text-center">Contacts</TableColumn>
          <TableColumn className="text-sm text-center">Actions</TableColumn>
        </TableHeader>
        <TableBody>
          {loading
            ? Array(take)
                .fill("")
                .map((_, key) => (
                  <TableRow key={key}>
                    <TableCell className="text-center">
                      <Skeleton className="h-3 w-3/5 rounded-lg" />
                    </TableCell>
                    <TableCell className="text-center">
                      <Skeleton className="h-3 w-3/5 rounded-lg" />
                    </TableCell>
                    <TableCell className="text-left pl-4">
                      <Skeleton className="h-3 w-3/5 rounded-lg" />
                    </TableCell>
                    <TableCell className="text-left">
                      <Skeleton className="h-3 w-3/5 rounded-lg" />
                    </TableCell>
                    <TableCell className="text-center">
                      <Skeleton className="h-3 w-3/5 rounded-lg" />
                    </TableCell>
                    <TableCell className="text-center">
                      <Skeleton className="h-3 w-3/5 rounded-lg" />
                    </TableCell>
                    <TableCell className="text-center">
                      <Skeleton className="h-3 w-3/5 rounded-lg" />
                    </TableCell>
                    <TableCell className="text-center">
                      <Skeleton className="h-3 w-3/5 rounded-lg" />
                    </TableCell>
                    <TableCell className="text-center">
                      <Skeleton className="h-3 w-3/5 rounded-lg" />
                    </TableCell>
                    <TableCell className="text-center">
                      <Button variant="bordered" color="primary">
                        <Skeleton className="h-3 w-3/5 rounded-lg" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            : loads.map((load, key) => (
                <TableRow key={key}>
                  <TableCell className="text-center">{key + 1}</TableCell>
                  <TableCell className="text-center">
                    {load.load.pickUpDate}
                  </TableCell>
                  <TableCell className="text-left pl-4">
                    {load.places[0]?.address}
                  </TableCell>
                  <TableCell className="text-left">
                    {load.places[load.places.length - 1]?.address}
                  </TableCell>
                  <TableCell className="text-center">
                    {load.places.length}
                  </TableCell>
                  <TableCell className="text-center">{load.company}</TableCell>
                  <TableCell className="text-center">
                    {load.load.weight}
                  </TableCell>
                  <TableCell className="text-center">
                    {load.load.rate}
                  </TableCell>
                  <TableCell className="text-center">{load.email}</TableCell>
                  <TableCell className="text-center">
                    <Button
                      variant="bordered"
                      color="primary"
                      className="hover:bg-primary-100"
                      as={Link}
                      to={`/loadinfo/${load.id}`}
                    >
                      View Load
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>

      <div className="flex gap-2 justify-end mt-6">
        <Button
          className="disabled:opacity-50"
          color="primary"
          disabled={skip === 0}
          onClick={() => setSkip(skip - take)}
        >
          Prev
        </Button>
        <Button
          className="disabled:opacity-50"
          color="primary"
          disabled={loads.length < take}
          onClick={() => setSkip(skip + take)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default LoadTable;
