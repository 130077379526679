import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useState } from "react";
import { Link } from "react-router-dom";

export const AccountNotVerifiedModal = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  const handleInstructionsClick = () => {
    handleClose();
    const element = document.getElementById("tutorial-4");
    element?.click();
  };

  return (
    <Modal
      size="2xl"
      isOpen={show}
      onOpenChange={setShow}
      onClose={handleClose}
      className="p-0 rounded-2xl"
    >
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody className="w-full flex flex-col gap-7 text-base text-gray-900 [&_a]:text-brand-700 hover:[&_a]:underline">
          <img
            className="rounded-[20px]"
            src="img/others/verification.png"
            width={1048}
            height={714}
            alt="img"
          />
          <div className="flex flex-col gap-3 mb-3 w-full">
            <div className="flex gap-2 items-center">
              <h2>
                <span className="text-brand-700">Please verify</span> your email
              </h2>
              <img
                alt="envelope-heart-emoji"
                src="img/emojis/envelope-heart-emoji.png"
                className="w-9 h-9"
              />
            </div>
            <p>
              You're not able to send email just yet.
              <br />
              Please click on the link below to finish your email verification.
            </p>
          </div>
        </ModalBody>
        <ModalFooter className="flex justify-between">
          <Button
            color="primary"
            variant="bordered"
            onClick={handleInstructionsClick}
          >
            Instructions
          </Button>
          <Button as={Link} to="/email" color="primary" onClick={handleClose}>
            Verify
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
