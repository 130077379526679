import { Template } from "@/types";
import { callApi } from "./api.service";

export async function getTemplateById(id: number): Promise<Template> {
  const resp = await callApi(`/templates/${id}`, "get", true, {});
  const template: Template = resp.data;
  return template;
}

export async function getTemplates(): Promise<Template[]> {
  const resp = await callApi("/templates", "get", true, {});
  const templates: Template[] = resp.data;
  return templates;
}

export async function addTemplate(data: Template): Promise<Template> {
  const resp = await callApi("/templates/add-template", "post", true, data);
  const template: Template = resp.data;
  return template;
}

export async function updateTemplate(data: Template): Promise<Template> {
  const resp = await callApi(`/templates/${data.id}`, "put", true, data);
  const template: Template = resp.data;
  return template;
}

export async function deleteTemplate(id: number): Promise<Template> {
  const resp = await callApi(`/templates/${id}`, "delete", true, {});
  const template: Template = resp.data;
  return template;
}
