import { WarningIcon } from '@/components/icons'
import { Button } from '@nextui-org/button'
import { Modal, useDisclosure, ModalContent, ModalFooter, ModalHeader, ModalBody } from '@nextui-org/react'

const DeleteButton = ({ children, onDelete, content }: { children: any, onDelete: any, content?: string }) => {
    const { onOpen, onClose, isOpen } = useDisclosure()

    const handleDelete = () => {
        onClose()
        onDelete()
    }

    return (
        <>
            <Button
                isIconOnly
                variant='light'
                onClick={onOpen}
                className='data-[pressed=true]:scale-100'
            >
                {children}
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalBody>
                        <div className='flex gap-6 items-center'>
                            <div className='text-danger'>
                                <WarningIcon size={42} />
                            </div>
                            <div className='flex flex-col gap-1'>
                                <h3>Are you sure?</h3>
                                {
                                    content ? content : (
                                        <p>Do you really delete this record? This process cannot be undone.</p>
                                    )
                                }
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='data-[pressed=true]:scale-100' aria-label='close' onClick={onClose}>Cancel</Button>
                        <Button className='data-[pressed=true]:scale-100' aria-label='delete' color='danger' onClick={handleDelete}>Delete</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DeleteButton
