import { MyLoadBoards } from "./components/MyLoadBoards";
import { Tutorials } from "./components/Tutorials";

const Welcome = () => {
  return (
    <div className="flex flex-col w-full h-full">
      <h2 className="absolute font-medium text-2xl px-8 top-5">
        Welcome to LoadConnect
      </h2>
      <MyLoadBoards />
      <Tutorials />
    </div>
  );
};

export default Welcome;
