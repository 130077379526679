import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";

export const ExtensionHandlers = () => {
  const { accessToken, refreshToken } = useSelector(
    (state: RootState) => state.authReducer.value
  );

  return (
    <div hidden id="tokens-ext">
      <p id="accessToken">{accessToken}</p>
      <p id="refreshToken">{refreshToken}</p>
    </div>
  );
};
