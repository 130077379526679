import SignInPage from "@/app/auth/sign-in";
import { Modal, ModalContent, Card, CardBody, Image } from "@nextui-org/react";
import React from "react";

const AuthModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: any }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent className="overflow-hidden">
        <Card className="min-w-[320px] md:min-w-[450px]" shadow="sm">
          <CardBody className="md:p-10 text-center">
            <div className="flex justify-center mb-[15px]">
              <Image
                width={50}
                height={50}
                alt="LoadConnect"
                src="/img/logo.png"
              />
            </div>
            <div>
              <h3 className="mb-1">Welcome to LoadConnect</h3>
              <SignInPage />
            </div>
          </CardBody>
        </Card>
      </ModalContent>
    </Modal>
  );
};

export default AuthModal;
